const INITIAL_STATE = {
  userUid: null,
  authError: null,
  setDefult: null,
  notify: false,
  isFirstLogin:null,
};

const piattoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "USERINFO":
      return { ...state, userUid: action.payload };
    case "SETDEFULT":
      return { ...state, setDefult: action.payload };
    case "SIGNUP_SUCCESS":
      return { ...state, authError: null };
    case "SIGNUP_ERROR":
      return { ...state, authError: action.err.message };
    case "SETINITIALSTATE":
      return { ...INITIAL_STATE };
    case "SETNOTIFY":
      return { ...state, notify: action.payload };
    case "ISFIRSTLOGIN":
      return { ...state, isFirstLogin: action.payload };
    default:
      return state;
  }
};
export default piattoReducer;
