import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import { firebaseReducer } from "react-redux-firebase";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import {
  createFirestoreInstance,
  firestoreReducer,
  getFirestore,
  reduxFirestore,
} from "redux-firestore";
import logger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import piattoReducer from "./reducers";
const middlewares = [thunk.withExtraArgument(getFirestore)];

const fbConfig = {
  apiKey: "AIzaSyD0pDLBcKWmpaQbQbBu-lv6FE9sUcp-6iY",
  authDomain: "piatto-97499.firebaseapp.com",
  databaseURL: "https://piatto-97499-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "piatto-97499",
  storageBucket: "piatto-97499.appspot.com",
  messagingSenderId: "735718058006",
  appId: "1:735718058006:web:f4944fa2b468c1c2f56d37",
  measurementId: "G-N60ZJ7WVKD"
 };
 

const rrfConfig = {
  userProfile: "VenueManagement",
  useFirestoreForProfile: true,
};
const persistConfig = {
  key: "root",
  storage: localStorage,
};
const app = firebase.initializeApp(fbConfig);

firebase.firestore();
firebase.storage();
firebase.analytics();
firebase.analytics().logEvent('notification_received');

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  state: piattoReducer,
});
export const persistedReducer = persistReducer(persistConfig, rootReducer);
export const functions = firebase.functions();
if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}
export const store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middlewares), reduxFirestore(firebase, fbConfig))
);

export const persistor = persistStore(store);

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};


