import react, { useEffect } from "react";
import "@fontsource/montserrat"; // Defaults t weight 400
import "@fontsource/montserrat/400.css"; // Specify weight
import "@fontsource/montserrat/400-italic.css"; // Specify weight and style
import SignIn from "./pages/Login";
import SignUp from "./pages/SignUp";
import Orders from "./pages/Orders";
import MenuPage from "./pages/MenuPage";
import UserAccountDetails from "./pages/UserAccountDetails";
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
} from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import AddBusiness from "./pages/AddBusiness";
import Header from "./components/Header";
// import Manage from "./pages/Manage";
import ResetPassword from "./pages/ResetPassword";
import Landing from "./pages/Landing/Landing";
import About from "./pages/About";
import DishManageList from "./pages/DishManageList";
import uploadAdmin from "./pages/uploadAdmin";
import AudioNotification from "./components/AudioNotification";
import BussinessPage from "./pages/BusinessPage";
import ReservationPage from "./pages/ReservationPage";
import AOS from "aos";
import "aos/dist/aos.css";
import MultiRouteWrapper from "../src/guide/Wrapper";
import { AppProvider } from "./context";

const animation = {
  // make it false to switch off fade-up animation
  animate: true,
  // animation playing duration
  duration: 750,
  // if true, animation plays only once when element comes on screen
  once: false,
};

function App() {
  const style = { textDecoration: "none" };
  useEffect(() => {
    AOS.init({
      duration: animation.duration,
      once: animation.once,
      disable: !animation.animate,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <AppProvider>
      <Router>
        <MultiRouteWrapper />
        <Header />
        <Switch>
          <Route path="/" exact component={Landing} />
          <Route path="/SignIn" exact component={SignIn} />
          <Route path="/Home" exact component={Home} />
          <Route path="/SignUp" exact component={SignUp} />
          <Route path="/Reset" exact component={ResetPassword} />
          <Route path="/Orders/:id" component={Orders} />
          <Route path="/Dishes/:id/:menuId" component={DishManageList} />
          <Route path="/Manage/:id" component={MenuPage} />
          <Route path="/Bussiness/:id" component={BussinessPage} />
          <Route path="/AddBusiness" exact component={AddBusiness} />
          <Route path="/LandingPage" exact component={Landing} />
          <Route path="/About" exact component={About} />
          <Route path="/UploadAdmin" exact component={uploadAdmin} />
          <Route
            path="/UserAccountDetails"
            exact
            component={UserAccountDetails}
          />
          <Route path="/reservations/:id" exact component={ReservationPage} />
          <Route component={NotFound} />
        </Switch>

        <AudioNotification />
      </Router>
    </AppProvider>
  );
}

export default App;
