import { Entypo } from "@native-base/icons";
import {
  Button,
  Center,
  Divider,
  Hidden,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Link,
  Pressable,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import logo from "../assets/logo.svg";
import GuestLayout from "../components/GuestLayout";
import IconGoogle from "../components/IconGoogle";
import { LoginToWebApp } from "../Redux/actions";
import Notiflix from "notiflix";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
export function SignInForm() {
  const [text, setText] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const HandleSignIn = () => {
    if (text.length > 0 && pass.length > 0) {
      dispatch(LoginToWebApp(text, pass));
    } else {
      Notiflix.Report.Failure(
        "Warning",
        `Please enter Email and Pasword `,
        "Close"
      );
    }
  };

  return (
    <VStack
      flex="1"
      px="6"
      py="9"
      _light={{ bg: "white" }}
      _dark={{ bg: "coolGray.800" }}
      space="3"
      justifyContent="space-between"
      borderTopRightRadius={{ base: "2xl", md: "xl" }}
      borderBottomRightRadius={{ base: "0", md: "xl" }}
      borderTopLeftRadius={{ base: "2xl", md: "0" }}
    >
      <VStack space="7">
        <Hidden till="md">
          <Text fontSize="3xl" fontWeight="bold">
            Welcome back,
          </Text>
          <Text fontSize="lg" fontWeight="normal">
            Sign in to continue!
          </Text>
        </Hidden>
        <VStack>
          <VStack space="3">
            <VStack space="4">
              <Input
                py="3"
                isRequired
                label="Email"
                placeholder="Email"
                labelColor="#9ca3af"
                labelBGColor={useColorModeValue("#fff", "#1f2937")}
                borderRadius="4"
                defaultValue={text}
                onChangeText={(txt) => setText(txt)}
                fontWeight="semibold"
                _text={{
                  fontSize: "sm",
                  fontWeight: "semibold",
                }}
                _dark={{
                  borderColor: "coolGray.700",
                }}
                _light={{
                  borderColor: "coolGray.300",
                }}
              />
              <Input
                py="3"
                isRequired
                type={showPass ? "" : "password"}
                placeholder="Password"
                label="Password"
                borderRadius="4"
                labelColor="#9ca3af"
                labelBGColor={useColorModeValue("#fff", "#1f2937")}
                defaultValue={pass}
                onChangeText={(txt) => setPass(txt)}
                fontWeight="semibold"
                InputRightElement={
                  <IconButton
                    mr="1"
                    variant="unstyled"
                    icon={
                      <Icon
                        size="4"
                        color="coolGray.400"
                        as={Entypo}
                        name={showPass ? "eye" : "eye-with-line"}
                      />
                    }
                    onPress={() => {
                      setShowPass(!showPass);
                    }}
                  />
                }
                _text={{
                  fontSize: "sm",
                  fontWeight: "medium",
                }}
                _dark={{
                  borderColor: "coolGray.700",
                }}
                _light={{
                  borderColor: "coolGray.300",
                }}
              />
            </VStack>

            <Link
              onPress={() => history.push("/Reset")}
              ml="auto"
              _text={{
                fontSize: "xs",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              _light={{
                _text: {
                  color: "primary.900",
                },
              }}
              _dark={{
                _text: {
                  color: "primary.700",
                },
              }}
            >
              Reset Password?
            </Link>

            <Button
              onPress={() => HandleSignIn()}
              mt="5"
              size="md"
              py="3"
              borderRadius="4"
              _text={{
                fontWeight: "medium",
              }}
              _light={{
                bg: "primary.900",
              }}
              _dark={{
                bg: "primary.700",
                _pressed: { bg: "primary.500" },
              }}
            >
              SIGN IN
            </Button>
            <HStack
              mt="5"
              space="2"
              mb={{ base: 6, md: 7 }}
              alignItems="center"
              justifyContent="center"
            >
              <Divider
                w="30%"
                _light={{ bg: { base: "coolGray.300", md: "coolGray.200" } }}
                _dark={{ bg: "coolGray.700" }}
              ></Divider>
              <Text
                fontWeight="medium"
                color={{ base: "coolGray.500", md: "coolGray.300" }}
                _light={{
                  color: { base: "coolGray.500", md: "coolGray.300" },
                }}
                _dark={{
                  color: { base: "coolGray.500", md: "coolGray.300" },
                }}
              >
                or
              </Text>
              <Divider
                w="30%"
                _light={{ bg: { base: "coolGray.300", md: "coolGray.200" } }}
                _dark={{ bg: "coolGray.700" }}
              ></Divider>
            </HStack>
          </VStack>
        </VStack>
      </VStack>
      <HStack
        space="1"
        safeAreaBottom
        alignItems="center"
        justifyContent="center"
      >
        <Text
          _light={{ color: "coolGray.800" }}
          _dark={{ color: "coolGray.400" }}
        >
          Don't have an account?
        </Text>

        <Link
          onPress={() => history.push("/SignUp")}
          _text={{
            fontWeight: "bold",
            textDecoration: "none",
            fontFamily: "Inclusive Sans, sans-serif"
          }}
          _light={{
            _text: {
              color: "primary.900",
            },
          }}
          _dark={{
            _text: {
              color: "primary.700",
            },
          }}
        >
          Sign up
        </Link>
      </HStack>
      <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
      <Link
          onPress={() => history.push("/SignUp")}
          _text={{
            // fontWeight: "bold",
            textDecoration: "none",
            fontFamily: "Inclusive Sans, sans-serif"
          }}
          _light={{
            _text: {
              color: "primary.900",
            },
          }}
          _dark={{
            _text: {
              color: "primary.700",
            },
          }}
        >
          Admin
        </Link>
      </div>
    </VStack>
  );
}
const SignIn = ({ auth }) => {
  if (auth.uid) {
    if (auth.email === "an@example.com") {
      return <Redirect to="/UploadAdmin" />;
    }
    // return <Redirect to="/Home" />;
     return <Redirect to="/Home?firstLogin=true" />;
  }

  return (
    <GuestLayout>
      <Hidden from="md">
        <VStack
          alignItems="center"
          textAlign={"center"}
          px="4"
          mb="5"
          space="2"
        >
          <Image
            source={{ uri: logo }}
            resizeMode="contain"
            size={200}
            alt="NativeBase logo"
          />
          <VStack mt="-10" space="2">
            <Text fontSize="3xl" fontWeight="bold">
              Welcome back,
            </Text>
            <Text fontSize="md" fontWeight="normal">
              Sign in to continue
            </Text>
          </VStack>
        </VStack>
      </Hidden>
      <Hidden till="md">
        <Center
          flex="1"
          bg="coolGray.50"
          borderTopLeftRadius={{ base: "0", md: "xl" }}
          borderBottomLeftRadius={{ base: "0", md: "xl" }}
        >
          <Image
            source={{ uri: logo }}
            style={{ fill: "red" }}
            resizeMode="contain"
            size={400}
            alt="NativeBase logo"
          />
        </Center>
      </Hidden>
      <SignInForm />
    </GuestLayout>
  );
};

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    auth: state.firebase.auth,
  };
};

export default compose(connect(mapStateToProps))(SignIn);
