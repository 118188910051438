


import {
    AspectRatio,
    Box,
    Center,
    HStack,
    Stack,
    Text,
    VStack,
    Image,
    Heading,
    IconButton,
    Icon,
    View,
    Spinner,
    Button,
  } from "native-base";
  import React from "react";

  import { useHistory } from "react-router-dom";
  import { compose } from "redux";
  import { connect, useDispatch, useSelector } from "react-redux";
  import { Redirect } from "react-router-dom";
  import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
  import * as xlsx from 'xlsx';
  import Notiflix from "notiflix";
  import firebase from "firebase/app";

import preccintJson from "./preccints.json" 




  function capitalizeFirstLetter(string) {
    if(string.length <= 1){
      return string
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
  
const AddBiz = async (data) => {
    if(`${data['bizId']}` ==='6'){
        return
    }
  
     
      let images = []
      async function callBack(downloadURL) {
     
          const BizImage = downloadURL;
          var obj = data;
          obj['bizId'] = `${data['bizId']}`
          
          obj['images'] = images
          obj['bizAddress']= `${data['bizStreetNo']} ${data['bizStreetName']} ${data['bizSuburb']} ${data['bizState']}`
          if(BizImage){
            obj['bizPic1'] = BizImage
          }else{
            obj['bizPic1'] = BizImage
          }

          obj['bizType'] = `${data['bizType']}`.split(',')

          obj['bizCuisines'] = `${data['bizCuisines']}`.split(',').map(s=>capitalizeFirstLetter(s.trim()))
         
          var obj2 = { timestamp: new Date(), };
          Object.assign(obj, obj2);
          console.log(obj)
          await firebase
            .firestore()
            .collection("Business")
            .doc(`${data['bizId']}`)
            .set(obj)
            .catch((err) => {
              Notiflix.Loading.Remove();
              Notiflix.Report.Failure(
                "somthing wrong",
                `${err.message}`,
                "Close"
              );
            });
          
          
         
        
      }
    //   listArray.forEach(async function(element) {
        
       
    //   });
    // push inside of array if it had one
    
    if(!data['bizPic1']){
        callBack(null)
    }else{
        const downloadURL = await firebase
    .storage()
    .ref(`${data['bizPic1']}`)
    .getDownloadURL()
    .catch((err) => {
      console.log('err')
    });
    if(downloadURL){
        images.push(downloadURL)
        //duplicatiing
        images.push(downloadURL)
        images.push(downloadURL)
        images.push(downloadURL)
        

        console.log(images)
     
        callBack(downloadURL);
    }else{
        callBack(null);
    }
    
    }
    
    
    
}
 
     
      



  const addBussiness =async(parsed)=>{
   
    Notiflix.Loading.Hourglass("Please Wait...");
    for(let i=1;i<parsed.length;i++){
        console.log('Add businees ',parsed[i])
        if(parsed[i]['bizId'] !== undefined){
            await AddBiz(parsed[i])
        }
       
      
    }
    Notiflix.Loading.Remove();
    
  }

const uploadPreccints =async()=>{
    Notiflix.Loading.Hourglass("Please Wait...");
    const firestore = firebase.firestore();
  
    const batch = firestore.batch()
    const ref =  firestore.collection(`precincts`)
  
    preccintJson.forEach(t=>{
      var docRef 
      docRef=ref.doc()
       batch.set(docRef,{
        ...t,
        id:docRef.id
       })
    })
    
    await batch.commit().catch(
      err=>{
        Notiflix.Loading.Remove();
        Notiflix.Report.Failure(
          "somthing wrong",
          `${err.message}`,
          "Close"
        );
      }
    )
    Notiflix.Loading.Remove();
  
}

  const AdminExport = ({ auth, isDefult }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [selectedFile,setSelectedFile] = React.useState(null)
 
 
   
    const onFileChange = event => {
 

        setSelectedFile(event.target.files[0])
 
    };

    const uploadPreccintsFb = upload =>{
      
       uploadPreccints()

    }
 
    // On file upload (click the upload button)
    const onFileUpload = () => {
 
        // Create an object of formData
        const formData = new FormData();
 
        // Update the formData object
        formData.append(
            "myFile",
            selectedFile,
            selectedFile.name
        );
 
        // Details of the uploaded file
        console.log(selectedFile);

   

        const promise = new Promise((resolve,reject)=>{

            const fileReader = new FileReader()

            fileReader.readAsArrayBuffer(selectedFile)

            fileReader.onload =e=>{
                const bufferArray = e.target.result

                const wb = xlsx.read(bufferArray,{type:'buffer'})

                const wsname = wb.SheetNames[1]

                const ws = wb.Sheets[wsname]

                const bussinessData = xlsx.utils.sheet_to_json(ws,{raw:false})

                console.log(wb.SheetNames)

                const allData =[bussinessData]
                resolve(allData)


            }

            fileReader.onerror=(errr)=>{
                reject(errr)
            }


        })

        promise.then(data=>{
            console.log(data)
            console.log('... busineess')
            addBussiness(data[0])
        })
 
        // Request made to the backend api
        // Send formData object
        // axios.post("api/uploadfile", formData);
    };
 
    // File content to be displayed after
    // file upload is complete
  
 
   
        return (
            <Center height={'100%'}>
              
              <Box>
              <Text fontSize={'2xl'}>
                    Hello, Admin  upload firebase schema here
                </Text>
                <Box m={3}>
                    <input type="file" onChange={onFileChange} accept=".xml,.xlsx"/>
                    <button onClick={onFileUpload} style={{marginTop:'10px'}}>
                       upload
                    </button>
                    <button onClick={uploadPreccintsFb}  style={{marginTop:'10px'}}>
                      upload Precincts
                    </button>

                </Box>
              
              </Box>
               
            </Center>
        );
    
  }
  
  const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth,
      isDefult: state.state.setDefult,
    };
  };
  
  export default compose(connect(mapStateToProps))(AdminExport);
  