import {
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Modal,
  Select,
  Spinner,
  Stack,
  Text,
  TextArea,
  View,
  VStack,
  DeleteIcon,
  AddIcon

} from "native-base";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getFirebase, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { Redirect, useHistory } from "react-router-dom";
import TimeInput from "react-time-picker-input";
import { compose } from "redux";
import MultiImageInput from "../components/multi-image-input";
import {
  AddDish,
  AddMenu,
  DishDelete,
  DishONOFF,
  DishOptionTrigers,
  DishUpdate,
  MenuONOFF,
  MenuUpdate,
  SetDishOption,
  DishOptionDelete
} from "../Redux/actions";
import { v4 as uuidv4 } from "uuid";

import { TableSimple } from "react-pagination-table";
import { usePagination, useTable } from "react-table";

import { TablePagination } from "react-pagination-table";
import { useDishesList } from "./Pagination";

import DishOptions from "../components/DishOptions";

const DishManageList = (props) => {
  const id = props.match.params.id;
  const menuID = props.match.params.menuId;
  const { DishDetails, limit, setLimit, page, handlePageChange } = useDishesList({
    id,
    menuID,
  });
  console.log(DishDetails, '-DishDetails')
  const history = useHistory();
  const { auth } = props;
  const [MenuModal, setMenuModal] = React.useState(false);
  const [MenuON, setMenuON] = useState("08:00");
  const [MenuOFF, setMenuOFF] = useState("21:30");
  const [MenuType, setMenuType] = useState("");
  const [MenuISOn, setMenuISOn] = useState(true);
  const [Dis, setDis] = useState("");

  //  dish stuff  
  const [DishModal, setDishModal] = React.useState(false);
  const [dishMenuId, setDishMenuId] = React.useState("");
  const [images, setImages] = React.useState({});
  const [IsMenuUpdateRequest, setIsMenuUpdateRequest] = React.useState(false);
  const [MenuIDForUpdating, setMenuIDForUpdating] = React.useState("");
  const [DishIDForUpdating, setDishIDForUpdating] = React.useState("");
  const [IsDishUpdateRequest, setIsDishUpdateRequest] = React.useState(false);
  const [RecordedDishID, setRecordedDishID] = React.useState("");

  // const [DishPrice1, setDishPrice1] = useState("");
  // const [DishPrice2, setDishPrice2] = useState("");
  // const [DishPrice3, setDishPrice3] = useState("");
  // const [DishPrice4, setDishPrice4] = useState("");

  const [dishName, setDishName] = useState("");
  const [dishDesc, setDishDis] = useState("");
  const [dishCal, setDishCal] = useState("");
  const [dishTransFat, setDishTransFat] = useState("");
  const [dishPolySatFat, setDishPolySatFat] = useState("");
  const [dishMonoFat, setMonoFat] = useState("");

  const [dishSatFat, setdishSatFat] = useState("");
  const [dishTotFat, setDishTotFat] = useState("");
  const [dishAvailFrom, setDishAvailFrom] = useState("08:00");
  const [dishAvailTill, setDishAvailTill] = useState("21:30");

  const [dishOn, setDishOn] = useState(true);

  // Dish is already low GI
  const [dishLGI, setdishLGI] = useState(false);
  //  Dish is already Gluten Free
  const [dishGF, setDishGF] = useState(false);
  // Dish is already Dairy Free
  const [dishDF, setDishDF] = useState(false);
  // Dish is already Nut Free
  const [dishNF, setDishNF] = useState(false);
  // Dish is already Vegetarian
  const [dishV, setDishV] = useState(false);
  // Dish is already Vegan
  const [dishVG, setDishVG] = useState(false);
  // alcolic
  const [dishAlc, setDishAlc] = useState(false)

  // availibility // remove
  // Gluten Free Option Avaialable 
  const [dishGFO, setdishGFO] = useState(false);
  //Dairy Free Option Available 
  const [dishDFO, setDishDFO] = useState(false);
  // Nut Free Option Available
  const [dishNFO, setDishNFO] = useState(false);
  //Vegan Option Available
  const [dishVGO, setDishVGO] = useState(false);


  const [openDishOptionDish, setCurrentDishOptionsItem] = useState(null)


  const [dishAvailSize, setDishAvailSize] = useState([{
    size: '',
    price: 0,
    id: uuidv4()
  }])

  const dishSizeComponent = (id, size, price) => {
    // dish siz eprice 

    return (
      <HStack p='2' key={id} space={3} justifyContent="center">
        <Input
          onChangeText={(e) => handleEditSizePrice(id, undefined, e)}
          value={size}
          maxLength={50}
          placeholder="Size"
        />
        <Input
          onChangeText={(e) => handleEditSizePrice(id, e, undefined)}
          value={price === 0 ? null : price}
          type="number"
          maxLength={50}
          placeholder="Price"
        />
        <IconButton onPress={() => deleteAvaileSize(id)}>
          <DeleteIcon />
        </IconButton>
      </HStack>
    )

  }

  const handleEditSizePrice = (id, price, s) => {
    // edts the prices 
    console.log(id, price, s)
    setDishAvailSize(sizes => {
      sizes = [...sizes]
      console.log(sizes)
      var size = sizes.find((s) => s['id'] === id)
      if (price !== undefined) {
        size['price'] = price
      }
      if (s !== undefined) {
        size['size'] = s
      }

      return sizes
    })
  }

  const handleAddSizePrice = () => {
    setDishAvailSize(
      sizes => {
        sizes = [...sizes, {
          size: '',
          price: 0,
          id: uuidv4()
        }]


        return sizes

      }
    )

  }

  const deleteAvaileSize = (id) => {
    setDishAvailSize(sizes => {
      sizes = [...sizes]
      return sizes.filter((s) => s['id'] !== id)
    })
  }


  const crop = {
    unit: "%",
    aspect: 4 / 3,
    width: "100",
  };
  const dispatch = useDispatch();
  const handleSave = () => {
    if (MenuType.length > 1 && Dis.length > 0) {
      setMenuModal(false);
      const bizId = id;
      const data = { MenuType, MenuON, MenuOFF, MenuISOn, Dis, bizId };
      dispatch(AddMenu(data));
    } else {
      Notiflix.Report.Warning(
        "Warning",
        `please fill all Menu information`,
        "Close"
      );
    }
  };

  const handleDishSave = () => {
    if (
      Object.keys(images).length > 0 &&
      // DishPrice1.length > 0 &&
      // DishPrice2.length > 0 &&
      // DishPrice3.length > 0 &&
      // DishPrice4.length > 0 &&
      dishName.length > 0 &&
      dishDesc.length > 0 &&
      dishCal.length > 0 &&
      dishTransFat.length > 0 &&
      dishSatFat.length > 0 &&
      dishMonoFat.length > 0 &&
      dishPolySatFat.length > 0 &&
      dishTotFat.length > 0 &&
      dishAvailSize.length > 0 &&
      dishAvailSize[0]['price'] > 0
    ) {
      const bizId = id;
      const data = {
        dishMenuId,
        bizId,
        images,
        // DishPrice1,
        // DishPrice2,
        // DishPrice3,
        // DishPrice4,
        dishName,
        dishDesc,
        dishCal,
        dishTransFat,
        dishSatFat,
        dishTotFat,
        dishAvailFrom,
        dishAvailTill,
        dishOn,
        dishLGI,
        dishGF,
        dishDF,
        dishNF,
        dishV,
        dishVG,
        dishGFO,
        dishMonoFat,
        dishPolySatFat,
        dishDFO,
        dishNFO,
        dishVGO,
        dishAvailSize,
        dishAlc
      };
      setDishModal(false);
      dispatch(AddDish(data));
    } else {
      Notiflix.Report.Warning(
        "Warning",
        `please fill all Dish information`,
        "Close"
      );
    }
  };

  // const handleEditMenu = (item) => {
  //   setIsMenuUpdateRequest(true);
  //   setDis(item.Dis);
  //   setMenuON(item.MenuON);
  //   setMenuOFF(item.MenuOFF);
  //   setMenuType(item.MenuType);
  //   setMenuIDForUpdating(item.id);
  //   setMenuModal(true);
  // };
  const scroller = () => {
    window.scrollTo(0, 0);
  };
  const handleEditDish = (item) => {
    if (item.images) {
      const imageedit = item.images;
      setImages(imageedit);
    } else {
      const imageedit = {};
      setImages(imageedit);
    }
    scroller();
    setIsDishUpdateRequest(true);
    setDishName(item.dishName);
    setDishDis(item.dishDesc ? item.dishDesc : "N/A");
    // setDishPrice1(item.DishPrice1 ? item.DishPrice1 : "0");
    // setDishPrice2(item.DishPrice2 ? item.DishPrice2 : "0");
    // setDishPrice3(item.DishPrice3 ? item.DishPrice3 : "0");
    // setDishPrice4(item.DishPrice4 ? item.DishPrice4 : "0");
    setDishCal(item.dishCal ? item.dishCal : "N/A");
    setDishTransFat(item.dishTransFat ? item.dishTransFat : "N/A");
    setDishTotFat(item.dishTotFat ? item.dishTotFat : "N/A");
    setdishSatFat(item.dishSatFat ? item.dishSatFat : "N/A");
    setDishAvailFrom(item.dishAvailFrom);
    setDishAvailTill(item.dishAvailTill);
    setDishAvailSize([...item.dishAvailSize.map(s => ({ ...s }))]);
    setDishPolySatFat(
      item.dishPolySatFat ? item.dishPolySatFat : "N/A"
    );
    setMonoFat(
      item.dishMonoFat ? item.dishMonoFat : "N/A"
    );
    setDishIDForUpdating(item.id);
    setRecordedDishID(item.DishID);
    setDishModal(true);

  };
  console.log(dishAvailSize)

  useFirestoreConnect([

    {
      collection: "Business",
      where: [["bizId", "==", id]],
      oderBy: [["timestamp", "asc"]],
    }

  ]);

  const business = useSelector(({ firestore: { ordered } }) => {
    return ordered.Business;
  });

  // if (!auth.uid ) return <Redirect to="/" />;
  if (DishDetails) {
    console.log(DishDetails)
    return (
      <VStack
        flex="1"
        _light={{ bg: "white" }}
        _dark={{ bg: "coolGray.800" }}
        space="3"
        justifyContent="space-between"
      >
        <Center w={"100%"}>
          <Text color={"white"} position={"absolute"} zIndex={99999} top={-50}>
            {business[0].bizName}
          </Text>

          <VStack px="4" my={{ base: "10", md: "1" }}>
            <VStack
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              space={5}
            >
              <Button mb={1} onPress={() => history.goBack()} w={"100%"}>
                Back to Menu Page  1
              </Button>
              <Button
                onPress={() => {
                  setDishMenuId(menuID);
                  setIsDishUpdateRequest(false);
                  setImages({});
                  // setDishName("");
                  setDishDis("N/A");
                  // setDishPrice1("");
                  // setDishPrice2("");
                  // setDishPrice3("");
                  // setDishPrice4("");
                  setDishAvailSize([{
                    size: '',
                    price: 0,
                    id: uuidv4()
                  }])
                  setDishCal("N/A");
                  setDishTransFat("N/A");
                  setDishTotFat("N/A");
                  setdishSatFat("N/A");
                  setDishPolySatFat("N/A");
                  setMonoFat("N/A");
                  setDishAvailFrom("08:00");
                  setDishAvailTill("21:30");
                  setDishIDForUpdating("");
                  setDishModal(true);
                }}
                w={"100%"}
                mt={-4}
                colorScheme="coolGray"
              >
                Add Dish
              </Button>
              {DishDetails &&
                DishDetails.map(function (item, i) {
                  return (
                    <Box key={i} w={"100%"} alignItems="center">
                      <Box
                        w={"full"}
                        rounded="lg"
                        overflow="hidden"
                        borderColor="coolGray.200"
                        borderWidth="1"
                        _dark={{
                          borderColor: "coolGray.600",
                          backgroundColor: "gray.700",
                        }}
                        _web={{
                          shadow: 2,
                          borderWidth: 0,
                        }}
                        _light={{
                          backgroundColor: "gray.50",
                        }}
                      >
                        <Button
                          colorScheme={item.dishOn ? "green" : "red"}
                          isDisabled={!item.dishOn}
                          size={"xs"}
                        >
                          {item.dishOn ? "Dish is On" : "Dish is Off"}
                        </Button>

                        <HStack
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          space={2}
                          p={5}
                        >
                          <Image
                            w={"100"}
                            h={"100"}
                            borderRadius="25"
                            source={{
                              uri: item.DishImage,
                            }}
                            alt="image"
                          />
                          <Heading textTransform={"capitalize"} size="md" ml="-1">
                            {item.DishName}
                          </Heading>
                          <Text fontWeight="400">{item.dishDesc}</Text>
                          <VStack space={1} alignItems="center">
                            <Text>from: {item.dishAvailFrom}</Text>
                            <Text>tilld: {item.dishAvailTill}</Text>
                          </VStack>

                          <VStack space={1} alignItems="center">
                            {
                              (item.dishAvailSize ?? []).map(
                                s => {
                                  return (
                                    <Text key={s['id']}>
                                      {s['size']} price: $
                                      {s['price']}
                                    </Text>
                                  )
                                }
                              )
                            }

                          </VStack>

                          {/* <VStack space={1} alignItems="center">
                          
                          <Text>
                            Small dish price: $
                            {item.DishPrice1.indexOf("$") > -1
                              ? item.DishPrice1.replace("$", "")
                              : item.DishPrice1}
                          </Text>
                          <Text>
                            Medium Dish Price: $
                            {item.DishPrice2.indexOf("$") > -1
                              ? item.DishPrice2.replace("$", "")
                              : item.DishPrice2}
                          </Text>
                          <Text>
                            Large Dish Price: $
                            {item.DishPrice3.indexOf("$") > -1
                              ? item.DishPrice3.replace("$", "")
                              : item.DishPrice3}
                          </Text>
                          <Text>
                            Huge Dish Price: $
                            {item.DishPrice4.indexOf("$") > -1
                              ? item.DishPrice4.replace("$", "")
                              : item.DishPrice4}
                          </Text>
                        </VStack> */}

                          <VStack space={1}>
                            <Button
                              onPress={() => {
                                handleEditDish(item);
                              }}
                              size={"xs"}
                            >
                              Edit
                            </Button>
                            <Button
                              onPress={() => dispatch(DishDelete(item.id))}
                              size={"xs"}
                              colorScheme={"danger"}
                            >
                              Remove
                            </Button>
                            <Button
                              onPress={() =>
                                dispatch(DishONOFF(item.id, item.dishOn)) // have to fix this 
                              }
                              size={"xs"}
                              colorScheme={"gray"}
                            >
                              On || OFF
                            </Button>
                            <Button
                              onPress={() => {
                                console.log('open dish options')
                                setCurrentDishOptionsItem(item)
                              }}
                              size={"xs"}
                              colorScheme={"yellow"}
                            >
                              Dish Options
                            </Button>
                          </VStack>
                        </HStack>
                        <HStack
                          alignItems={"center"}
                          justifyContent={"center"}
                          flexWrap={"wrap"}
                          space={1}
                        >
                          <Button
                            onPress={() =>
                              dispatch(
                                DishOptionTrigers(item.id, {
                                  data: "dishLGI",
                                  value: !item.dishLGI,
                                })
                              )
                            }
                            colorScheme={item.dishLGI ? "cyan" : "gray"}
                            size={"xs"}
                          >
                            Low GI
                          </Button>
                          <Button
                            onPress={() =>
                              dispatch(
                                DishOptionTrigers(item.id, {
                                  data: "dishDF",
                                  value: !item.dishDF,
                                })
                              )
                            }
                            colorScheme={item.dishDF ? "cyan" : "gray"}
                            size={"xs"}
                          >
                            Dairy Free
                          </Button>
                          <Button
                            onPress={() =>
                              dispatch(
                                DishOptionTrigers(item.id, {
                                  data: "dishGF",
                                  value: !item.dishGF,
                                })
                              )
                            }
                            colorScheme={item.dishGF ? "cyan" : "gray"}
                            size={"xs"}
                          >
                            Gluten Free
                          </Button>
                          <Button
                            onPress={() =>
                              dispatch(
                                DishOptionTrigers(item.id, {
                                  data: "dishNF",
                                  value: !item.dishNF,
                                })
                              )
                            }
                            colorScheme={item.dishNF ? "cyan" : "gray"}
                            size={"xs"}
                          >
                            NutFree
                          </Button>
                          <Button
                            onPress={() =>
                              dispatch(
                                DishOptionTrigers(item.id, {
                                  data: "dishV",
                                  value: !item.dishV,
                                })
                              )
                            }
                            colorScheme={item.dishV ? "cyan" : "gray"}
                            size={"xs"}
                          >
                            Vegetarian
                          </Button>
                          <Button
                            onPress={() =>
                              dispatch(
                                DishOptionTrigers(item.id, {
                                  data: "dishVG",
                                  value: !item.dishVG,
                                })
                              )
                            }
                            colorScheme={item.dishVG ? "cyan" : "gray"}
                            size={"xs"}
                          >
                            Vegan
                          </Button>
                          <Button
                            onPress={() =>
                              dispatch(
                                DishOptionTrigers(item.id, {
                                  data: "dishGFO",
                                  value: !item.dishGFO,
                                })
                              )
                            }
                            colorScheme={item.dishGFO ? "cyan" : "gray"}
                            size={"xs"}
                          >
                            Gluten Free Option
                          </Button>
                          <Button
                            onPress={() =>
                              dispatch(
                                DishOptionTrigers(item.id, {
                                  data: "dishDFO",
                                  value: !item.dishDFO,
                                })
                              )
                            }
                            colorScheme={item.dishDFO ? "cyan" : "gray"}
                            size={"xs"}
                          >
                            Dairy Free Option
                          </Button>
                          <Button
                            onPress={() =>
                              dispatch(
                                DishOptionTrigers(item.id, {
                                  data: "dishNFO",
                                  value: !item.dishNFO,
                                })
                              )
                            }
                            colorScheme={item.dishNFO ? "cyan" : "gray"}
                            size={"xs"}
                          >
                            Nut Free Option
                          </Button>
                          <Button
                            onPress={() =>
                              dispatch(
                                DishOptionTrigers(item.id, {
                                  data: "dishVGO",
                                  value: !item.dishVGO,
                                })
                              )
                            }
                            colorScheme={item.dishVGO ? "cyan" : "gray"}
                            size={"xs"}
                          >
                            Vegan Option
                          </Button>
                        </HStack>
                      </Box>
                    </Box>
                  );
                })}
            </VStack>
          </VStack>
          <Center>
            <Modal
              safeAreaTop={true}
              isOpen={DishModal}
              onClose={() => setDishModal(false)}
              size={"lg"}
            >
              <Modal.Content>
                <Modal.CloseButton />
                {IsDishUpdateRequest ? (
                  <Modal.Header>{`Update Dish`}</Modal.Header>
                ) : (
                  <Modal.Header>{`Add New dish (${dishMenuId})`}</Modal.Header>
                )}
                <Modal.Body>
                  <VStack space={1}>
                    <VStack zIndex={99999}>
                      <MultiImageInput
                        images={images}
                        max={1}
                        setImages={setImages}
                        cropConfig={{ crop, ruleOfThirds: true }}
                      />
                    </VStack>
                    <FormControl.Label>Name:</FormControl.Label>
                    <Input
                      onChangeText={(e) => setDishName(e)}
                      defaultValue={dishName}
                      placeholder="name"
                    />
                    <FormControl.Label>Description:</FormControl.Label>
                    <TextArea
                      onChangeText={(e) => setDishDis(e)}
                      defaultdefaultValue={dishDesc}
                      h={20}
                      placeholder="Description"
                    />
                    <Text
                      fontStyle={"italic"}
                      fontSize="xs"
                      color={"red.500"}
                      alignSelf={"center"}
                    >
                      Note: If dish size is not available, enter 0
                    </Text>
                    <Heading size={'sm'}>
                      Availible Sizes and Prices
                      <IconButton ml={1} onPress={() => {
                        console.log('cliced')
                        handleAddSizePrice()
                      }}>
                        <AddIcon />
                      </IconButton>
                    </Heading>
                    {
                      dishAvailSize &&
                      <HStack
                        justifyContent={"center"}
                        flexWrap="wrap"
                        zIndex={9}
                        space={10}
                        w={"100%"}
                      > {
                          dishAvailSize.map(
                            s => {
                              return dishSizeComponent(s.id, s.size, s.price)
                            }
                          )
                        }
                      </HStack>
                    }

                    <Heading size={'sm'}>
                      Dish Details
                    </Heading>

                    <HStack
                      justifyContent={"center"}
                      flexWrap="wrap"
                      zIndex={9}
                      space={10}
                      w={"100%"}>



                      {/* <VStack>
                      <FormControl.Label>Small dish Price:</FormControl.Label>
                      <Input
                        onChangeText={(e) => setDishPrice1(e)}
                        defaultValue={DishPrice1}
                        placeholder="Small dish Price"
                      />
                    </VStack>
                    <VStack>
                      <FormControl.Label>Medium Dish Price:</FormControl.Label>
                      <Input
                        onChangeText={(e) => setDishPrice2(e)}
                        defaultValue={DishPrice2}
                        pl aceholder="Medium Dish Price"
                      />
                    </VStack>
                    <VStack>
                      <FormControl.Label>Large Dish Price:</FormControl.Label>
                      <Input
                        onChangeText={(e) => setDishPrice3(e)}
                        defaultValue={DishPrice3}
                        placeholder="Large Dish Price"
                      />
                    </VStack>
                    <VStack>
                      <FormControl.Label>Huge Dish Price:</FormControl.Label>
                      <Input
                        onChangeText={(e) => setDishPrice4(e)}
                        defaultValue={DishPrice4}
                        placeholder="Huge Dish Price"
                      />
                    </VStack> */}
                      <VStack>
                        <FormControl.Label>Calories:</FormControl.Label>
                        <Input
                          onChangeText={(e) => setDishCal(e)}
                          defaultValue={dishCal}
                          placeholder="Calories"
                        />
                      </VStack>
                      <VStack>
                        <FormControl.Label>Trans Fat:</FormControl.Label>
                        <Input
                          onChangeText={(e) => setDishTransFat(e)}
                          defaultValue={dishTransFat}
                          placeholder="Trans Fat"
                        />
                      </VStack>

                      <VStack>
                        <FormControl.Label>
                          Monounsaturated Fat:
                        </FormControl.Label>
                        <Input
                          onChangeText={(e) => setMonoFat(e)}
                          defaultValue={dishMonoFat}
                          placeholder="Monounsaturated Fat"
                        />
                      </VStack>
                      <VStack>
                        <FormControl.Label>
                          Polyunsaturated Fat:
                        </FormControl.Label>
                        <Input
                          onChangeText={(e) => setDishPolySatFat(e)}
                          defaultValue={dishPolySatFat}
                          placeholder="Polyunsaturated Fat"
                        />
                      </VStack>

                      <VStack>
                        <FormControl.Label>Saturated Fat:</FormControl.Label>
                        <Input
                          onChangeText={(e) => setdishSatFat(e)}
                          defaultValue={dishSatFat}
                          placeholder="Saturated Fat"
                        />
                      </VStack>
                      <VStack>
                        <Box w={"160"} />
                      </VStack>
                      <VStack>
                        <FormControl.Label>Available from:</FormControl.Label>
                        <TimeInput
                          value={dishAvailFrom}
                          fullTimeDropdown
                          onChange={(dateString) => setDishAvailFrom(dateString)}
                        />
                      </VStack>
                      <VStack>
                        <FormControl.Label>Available till:</FormControl.Label>
                        <TimeInput
                          value={dishAvailTill}
                          fullTimeDropdown
                          onChange={(dateString) => setDishAvailTill(dateString)}
                        />
                      </VStack>
                    </HStack>
                    {!IsDishUpdateRequest && (
                      <VStack>
                        <HStack w={"100%"} justifyContent={"center"} space="20">
                          <VStack>
                            <Text mb={2} fontWeight={"bold"} fontSize="lg">
                              Dish Features
                            </Text>
                            <Checkbox
                              onChange={(e) => setDishAlc(e)}
                              defaultIsChecked={dishAlc}
                              defaultValue={dishAlc}
                            >
                              Alcoholic
                            </Checkbox>
                            <Checkbox
                              onChange={(e) => setdishLGI(e)}
                              defaultIsChecked={dishLGI}
                              defaultValue={dishLGI}
                            >
                              Low GI
                            </Checkbox>
                            <Checkbox
                              onChange={(e) => setDishGF(e)}
                              defaultIsChecked={dishGF}
                              defaultValue={dishGF}
                            >
                              Gluten Free
                            </Checkbox>
                            <Checkbox
                              onChange={(e) => setDishDF(e)}
                              defaultIsChecked={dishDF}
                              defaultValue={dishDF}
                            >
                              Dairy Free
                            </Checkbox>
                            <Checkbox
                              onChange={(e) => setDishNF(e)}
                              defaultIsChecked={dishNF}
                              defaultValue={dishNF}
                            >
                              Nut Free
                            </Checkbox>
                            <Checkbox
                              onChange={(e) => setDishV(e)}
                              defaultIsChecked={dishV}
                              defaultValue={dishV}
                            >
                              Vegetarian
                            </Checkbox>
                            <Checkbox
                              onChange={(e) => setDishVG(e)}
                              defaultIsChecked={dishVG}
                              defaultValue={dishVG}
                            >
                              Vegan
                            </Checkbox>

                          </VStack>

                          <VStack>
                            <Text mb={2} fontWeight={"bold"} fontSize="lg">
                              Available Options
                            </Text>

                            <Checkbox
                              onChange={(e) => setdishGFO(e)}
                              defaultIsChecked={dishGFO}
                              defaultValue={dishGFO}
                            >
                              Gluten Free
                            </Checkbox>
                            <Checkbox
                              onChange={(e) => setDishDFO(e)}
                              defaultIsChecked={dishDFO}
                              defaultValue={dishDFO}
                            >
                              Dairy Free
                            </Checkbox>
                            <Checkbox
                              onChange={(e) => setDishNFO(e)}
                              defaultIsChecked={dishNFO}
                              defaultValue={dishNFO}
                            >
                              Nut Free
                            </Checkbox>
                            <Checkbox
                              onChange={(e) => setDishVGO(e)}
                              defaultIsChecked={dishVGO}
                              defaultValue={dishVGO}
                            >
                              Vegan
                            </Checkbox>
                          </VStack>
                        </HStack>

                        <Center>
                          <Checkbox
                            onChange={(e) => setDishOn(e)}
                            defaultIsChecked={dishOn}
                            defaultValue={dishOn}
                          >
                            <Text
                              fontSize={"lg"}
                              fontWeight={"bold"}
                              color={"red.500"}
                            >
                              Dish On
                            </Text>
                          </Checkbox>
                        </Center>
                      </VStack>
                    )}
                  </VStack>
                </Modal.Body>
                <Modal.Footer>
                  <HStack space={2}>
                    <Button
                      variant="ghost"
                      colorScheme="blueGray"
                      onPress={() => {
                        setDishModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                    {IsDishUpdateRequest ? (
                      <Button
                        onPress={() => {
                          if (
                            Object.keys(images).length > 0 &&
                            // DishPrice1.length > 0 &&
                            // DishPrice2.length > 0 &&
                            // DishPrice3.length > 0 &&
                            // DishPrice4.length > 0 &&
                            dishName.length > 0 &&
                            dishDesc.length > 0 &&
                            dishCal.length > 0 &&
                            dishTransFat.length > 0 &&
                            dishMonoFat.length > 0 &&
                            dishPolySatFat.length > 0 &&
                            dishSatFat.length > 0 &&
                            dishTotFat.length > 0 && dishAvailSize.length > 0 && dishAvailSize[0]['price'] > 0
                          ) {
                            const data = {
                              images,
                              dishAvailFrom,
                              dishAvailTill,
                              dishAvailSize,
                              dishName,
                              dishDesc,
                              dishCal,
                              dishTransFat,
                              dishMonoFat,
                              dishPolySatFat,
                              dishSatFat,
                              dishTotFat,
                              DishIDForUpdating,
                              RecordedDishID,
                            };
                            dispatch(DishUpdate(data));
                            setDishModal(false);
                          } else {
                            Notiflix.Report.Warning(
                              "Warning",
                              `please fill all Menu information`,
                              "Close"
                            );
                          }
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        onPress={() => {
                          handleDishSave();
                        }}
                      >
                        Save
                      </Button>
                    )}
                  </HStack>
                </Modal.Footer>
              </Modal.Content>
            </Modal>
          </Center>
          <DishOptions dish={openDishOptionDish} closeHandler={() => {
            setCurrentDishOptionsItem(null)
          }}
            onDishOptionEdited={(dish, option) => {
              SetDishOption(dish['id'], option)
            }}

            onDishOptionDeleted={(dish, option) => {
              console.log('deleteeeee')
              DishOptionDelete(dish['id'], option)
            }}
          />
          <Button onPress={() => handlePageChange(page + 1)}>Next</Button>
        </Center>
      </VStack>
    );
  } else {
    return (
      <View>
        <Center>
          <Spinner size={"lg"} />
        </Center>
      </View>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

export default compose(connect(mapStateToProps))(DishManageList);
