import { useState, useEffect } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { first, last } from "lodash";
import Notiflix from "notiflix";

export function useDishesList(props) {
  console.log(props)

  const [forwardPointer, setForwardPointer] = useState(null); // Forward pointer
  const [page, setPage] = useState(0); // current  page
  const [limit, setLimit] = useState(5); // limit
  const [Firstp, setFirstp] = useState(null); // Forward pointer

  useFirestoreConnect([
    {
      collection: "DishDetails",
      where: [
        ["bizId", "==", props.id],
        ["dishMenuId", "==", props.menuID],
      ],
      orderBy: [
        ["timestamp", "asc"],
        ["dishID", "asc"],
      ],

      startAfter: [forwardPointer, Firstp],
    }
  ]);


  const DishDetails = useSelector(({ firestore: { ordered } }) => ordered.DishDetails);

  // add pointer to back chain

  const handlePageChange = (newPage) => {
    if (DishDetails) {
      if (DishDetails.length < limit) {
        Notiflix.Notify.Warning("List of dishes ends");

      } else {
        console.log(DishDetails.length, "Dishdetails~~~~~~~")
        setForwardPointer(last(DishDetails)?.timestamp);
        console.log(last(DishDetails), "last-------")
        setFirstp(last(DishDetails)?.DishID);
        setPage(newPage);
        console.log(newPage, "newpage-------")
      }
      if (DishDetails.length === 0) {
        window.location.reload(false);
      }
      //   setFirstp(first(Dishes)?.timestamp);
      //   setForwardPointer(last(Dishes)?.timestamp);
      //   setPointer(forwardPointer);
    }
  };

  return { DishDetails, limit, setLimit, page, handlePageChange };
}
