import {
  Box,
  HStack,
  Icon,
  IconButton,
  Text,
  Image,
  Button,
  Pressable,
  Spinner,
  Avatar,
} from "native-base";
import React, { useRef, useEffect, useState } from "react";
import { MaterialIcons } from "@native-base/icons";
import logo from "../assets/logoW.svg";
import { useHistory } from "react-router-dom";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { Logout } from "../Redux/actions";
import { Menu } from "native-base";
import { useAppContext } from "../context";
import { useMount } from "react-use";

const Header = ({ auth, profile }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    setState,
    state: { run, tourActive, stepIndex },
  } = useAppContext();

  const urlParams = new URLSearchParams(window.location.search);
  const firstLoginParam = urlParams.get("firstLogin");
  const stepParam = urlParams.get("step");

  useEffect(() => {
    if (firstLoginParam === "true") {
      setState({ run: true, tourActive: true, stepIndex: 0 });
    }

    if (stepParam == 1) {
      console.log("tourActive", tourActive);
      setState({ run: true, stepIndex: 1 });
    }
  }, [firstLoginParam, stepParam]);

  return (
    <>
      <div id="header-div">
        <Box safeAreaTop bg="#6200ee" />
        {/* <Button onPress={()=>dispatch(TestAPI())}>test</Button> */}
        <HStack
          bg="coolGray.900"
          px="3"
          py="3"
          justifyContent="space-between"
          alignItems="center"
          w="100%"
        >
          <HStack alignItems="center">
            <Pressable onPress={() => history.push("/")}>
              <Image
                source={{ uri: logo }}
                resizeMode="contain"
                size={100}
                h="50px"
                alt="NativeBase logo"
              />
            </Pressable>
          </HStack>
          <HStack>
            {!profile.isLoaded ? (
              <Spinner size={"sm"} />
            ) : (
              <>
                {auth.uid && profile.isLoaded ? (
                  <HStack space={2} zIndex={9999}>
                    <Menu
                      zIndex={9999}
                      w="190"
                      trigger={(triggerProps) => {
                        return (
                          <>
                            <div id="routeA" style={{ marginRight: "10px" }}>
                              <Pressable
                                zIndex={9999}
                                accessibilityLabel="More options menu"
                                {...triggerProps}
                              >
                                <Avatar
                                  source={{
                                    uri: profile.avatarUrl
                                      ? profile.avatarUrl
                                      : null,
                                  }}
                                >
                                  {profile.displayName
                                    ? profile.displayName
                                      .toUpperCase()
                                      .charAt(0)
                                    : "*"}
                                </Avatar>
                              </Pressable>
                            </div>

                          </>
                        );
                      }}
                    >
                      <Menu.Item
                        zIndex={9999}
                        onPress={() => {
                          dispatch({ type: "SETDEFULT", payload: null });
                          history.push("/UserAccountDetails");
                        }}
                      >
                        <span className="inclusive-font-span ">
                          Account Details
                        </span>
                      </Menu.Item>
                      <Menu.Item
                        zIndex={9999}
                        onPress={() => {
                          dispatch({ type: "SETDEFULT", payload: null });
                          history.push("/");
                        }}
                      >
                        <span className="inclusive-font-span ">
                          Switch Business
                        </span>
                      </Menu.Item>
                      { }
                    </Menu>
                    <div id="routeB">
                      <Button
                        onPress={() => dispatch(Logout(history))}
                        colorScheme={"danger"}
                        fontFamily="Inclusive Sans, sans-serif"
                        style={{ height: "100%" }}
                        leftIcon={
                          <Icon
                            as={MaterialIcons}
                            name="login"
                            size="sm"
                            color="white"
                          />
                        }
                      >
                        <span className="inclusive-font-span white">
                          Logout{" "}
                        </span>
                      </Button>
                    </div>
                  </HStack>
                ) : (
                  <></>
                )}
              </>
            )}
          </HStack>
        </HStack>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};
export default compose(connect(mapStateToProps))(Header);
