import * as React from "react";

import handPhone from "../../../assets/handPhone.png";
import appStores from "../../../assets/appStores11.png";

const AppStoreSection = () => {
  return (
    <>
      <div class="div-15" data-aos="fade-down">
        <div class="div-16">
          <div class="column-8">
            <div class="div-17 div-app-store-4 marginal-top-span styled-span-red">
              Enabling <br />
              All <br />
              hospitality <br />
              for <br />
              All
              <br />
              people! <br />
              
            </div>
          </div>
          <div class="column-12 div-appstore-img">
            <img loading="lazy" srcSet={handPhone} />
            <a href="https://apps.apple.com/au/app/piatto/id1602642481">
              <img
                loading="lazy"
                className="img-app-store-3"
                srcSet={appStores}
              />
            </a>
          </div>
        </div>
      </div>
      <style jsx>{`
        .div-appstore-img {
          margin-left: 50px !important;
          margin-top: 40px;
        }
        .img-app-store-3 {
          width: 250px;
        }
        .div-app-store {
          disply: flex;
          flex-direction: column-app-store;
          overflow: hidden;
          position: relative;
          display: flex;
          align-items: start;
          padding: 50px 60px 20px;
        }
        @media (max-width: 991px) {
          .div-app-store {
            padding: 0 20px;
          }
        }
        .img-app-store {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .div-app-store-2 {
          position: relative;
          max-width: 70%;
          margin-top: 140px;
        }
        @media (max-width: 991px) {
          .div-app-store-2 {
            margin-top: 40px;
          }
        }
        .div-app-store-3 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-app-store-3 {
            flex-direction: column-app-store;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-app-store {
          display: flex;
          flex-direction: column-app-store;
          line-height: normal;
          width: 73%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-app-store {
            width: 100%;
          }
        }
        .div-app-store-4 {
          color: #a40204;
          text-align: center;
          position: relative;
          font: 700 32px Inclusive Sans, sans-serif;
        }
        @media (max-width: 991px) {
          .div-app-store-4 {
            max-width: 100%;
          }
        }
        .column-app-store-2 {
          display: flex;
          flex-direction: column-app-store;
          line-height: normal;
          width: 27%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-app-store-2 {
            width: 100%;
          }
        }
        .img-app-store-2 {
          aspect-ratio: 0.65;
          object-fit: auto;
          object-position: center;
          width: 165px;
          fill: #000;
          margin-top: 8px;
          max-width: 100%;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-app-store-2 {
            margin-top: 11px;
          }
        }
        .column-12 {
          display: block;
          flex-direction: column;
          line-height: normal;
          width: 78%;
          margin-left: 0px;
        }
           @media (max-width: 991px) {
          .column-12 {
           display: flex;
          flex-direction: column;
          line-height: normal;
          width: 78%;
          margin-left: 0px;
          }
        }
      `}</style>
    </>
  );
};

export default AppStoreSection;
