import { Image,Box } from 'native-base'
import WindowQr from '../../assets/windows-qr.svg'
import QRCode from 'qrcode.react';
import { WINDOW_QR_ID } from '../../utils/qr_util';
const WindowQrComponent =({bizId})=>{

    return(
        <div style={{height:'0px',width:'0px'}} >
        <div id={WINDOW_QR_ID}  style={{position:'relative',height:'567px',width:'567px',display:'none'}}>

        <img alt={'window qr'} src={WindowQr} />
        <Box position={'absolute'} style={{top:365,right:65}}>
        <QRCode value={JSON.stringify({bizId:bizId,tableNo:101})}  style={{ height:'140px',width:'140px'}}/>
        </Box>
        <Box position={'absolute'} style={{top:365,left:60}}>
        <QRCode value={'https://apps.apple.com/us/app/piatto/id1602642481'}  style={{ height:'140px',width:'140px'}}/>
        </Box>
        
        
       
        </div>
        </div>
       
       
      
    )
}

export default WindowQrComponent