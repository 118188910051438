import { Box, Center, Stack } from "native-base";
import React from "react";

export default function GuestLayout(props) {
  return (
    <>
      <Box
        safeAreaTop
        _light={{ bg: "primary.900" }}
        _dark={{ bg: "coolGray.900" }}
      />
      <Center my="auto" bg="coolGray.200" flex="1" p={{ md: 8 }}>
        <Stack
          w="100%"
          maxW={{ md: "1016px" }}
          flex={{ base: "1", md: undefined }}
          flexDirection={{ base: "column", md: "row" }}
        >
          {props.children}
        </Stack>
      </Center>
    </>
  );
}
