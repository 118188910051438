
import { Image,Box } from 'native-base'
import ServiceQr from '../../assets/service-desk-qr.png'
import QRCode from 'qrcode.react';
import { SERVICE_QR_ID } from '../../utils/qr_util';

const ServiceDeskQrComponent =({bizId})=>{

    // 
    return(
        <div style={{height:'0px',width:'0px'}}  >
        <div id={SERVICE_QR_ID}  style={{position:'relative',height:'567px',width:'567px',display:'none'}}>

        <img alt={'window qr'} src={ServiceQr} height={'788px'} />
        <Box position={'absolute'} style={{top:435,right:165}}>
        <QRCode value={`piatto://?bizId=${bizId}`}  style={{ height:'285px',width:'280px'}}/>
        </Box>
        
        </div>
        </div>
       
       
      
    )
}

export default ServiceDeskQrComponent