import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Button,
  Input,
  Text,
  useColorModeValue,
  Modal,
  Popover,
  ScrollView,
  Alert,
  VStack,
  HStack,
  IconButton,
  CloseIcon,
  Collapse,
} from "native-base";
import { UpdatePassword, UploadUserAvatar } from "../Redux/actions";
import { compose } from "redux";
import { useHistory, Redirect } from "react-router-dom";
import MultiImageInput from "../components/multi-image-input";

export function UserAccountDetails({ auth }) {
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [validationError, setValidationError] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const colorModeValue = useColorModeValue("#fff", "#1f2937");
  const [images, setImages] = useState({});

  const crop = {
    unit: "%",
    aspect: 4 / 3,
    width: "100",
  };
  // Effect to validate inputs whenever newPassword or confirmPassword changes
  useEffect(() => {
    validateInputs();
  }, [newPassword, confirmPassword]);

  const uploadUserAvatar = (value) => {
    setImages(value);
  };
  const handleComplete = () => {
    dispatch(UploadUserAvatar(images, history));
  };

  const handlePasswordUpdate = () => {
    if (newPassword === confirmPassword) {
      dispatch(UpdatePassword(oldPassword, newPassword, history));
      setValidationError("");
    } else {
      setValidationError("Passwords do not match");
    }
  };

  const handleCancelPasswordChange = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setShowChangePassword(false);
    setIsUpdateDisabled(true);
  };

  // Function to validate inputs and enable/disable update button
  const validateInputs = () => {
    const newPasswordValue = newPassword.trim();
    const confirmPasswordValue = confirmPassword.trim();

    if (
      newPasswordValue.length > 0 &&
      confirmPasswordValue === newPasswordValue
    ) {
      setIsUpdateDisabled(false);
      setValidationError("");
    } else if (
      newPasswordValue.length === 0 &&
      confirmPasswordValue.length === 0
    ) {
      setIsUpdateDisabled(true);
      setValidationError("");
    } else if (newPasswordValue.length === 0) {
      setIsUpdateDisabled(true);
      setValidationError("New Password cannot be blank.");
    } else if (
      confirmPasswordValue !== newPasswordValue &&
      (newPasswordValue.length !== 0 || confirmPasswordValue.length !== 0)
    ) {
      setIsUpdateDisabled(true);
      setValidationError("Passwords do not match.");
    } else {
      setIsUpdateDisabled(true);
      setValidationError("");
    }
  };

  const cancelDeleteAccount = () => {
    setIsOpen(false);
    setShowDeleteAccount(false);
    setShowAlert(true);
    // close alert after 2s;
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  if (auth.isLoaded && !auth.uid) return <Redirect to="/" />;

  return (
    <>
      <Collapse isOpen={showAlert}>
        <Alert w="100%" status={"success"}>
          <VStack space={2} flexShrink={1} w="100%">
            <HStack flexShrink={1} space={2} justifyContent="space-between">
              <HStack space={2} flexShrink={1}>
                <Text fontSize="md" color="coolGray.800">
                  Phew, we almost lost you there!
                </Text>
              </HStack>
              <IconButton
                variant="unstyled"
                _focus={{
                  borderWidth: 0,
                }}
                icon={<CloseIcon size="3" />}
                _icon={{
                  color: "coolGray.600",
                }}
              />
            </HStack>
          </VStack>
        </Alert>
      </Collapse>
      <div style={{ textAlign: "center", padding: "20px" }}>
        {/* style={styles.header} */}
        <div style={{ marginBottom: "20px" }}>
          <Text fontSize="4xl" marginBottom={50}>
            Account Management
          </Text>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Text>Email: {auth.email}</Text>
        </div>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MultiImageInput
            images={images}
            max={1}
            setImages={setImages}
            cropConfig={{ crop, ruleOfThirds: true }}
          />
        </div>
        <div
          style={{ margin: "0 auto", marginBottom: "20px", maxWidth: "300px" }}
        >
          <Button size="sm" onPress={handleComplete}>
            Upload Avatar
          </Button>
        </div>
        <div
          style={{ margin: "0 auto", marginBottom: "20px", maxWidth: "300px" }}
        >
          <Button size="sm">Change Email Address</Button>
        </div>
        <div
          style={{ margin: "0 auto", marginBottom: "20px", maxWidth: "300px" }}
        >
          {!showChangePassword ? (
            <Button size="sm" onPress={() => setShowChangePassword(true)}>
              Change Password
            </Button>
          ) : (
            <>
              <Input
                py="2"
                isRequired
                type="password"
                placeholder="Old Password"
                label="Old Password"
                borderRadius="4"
                labelColor="#9ca3af"
                labelBGColor={colorModeValue}
                value={oldPassword}
                onChangeText={(txt) => setOldPassword(txt)}
                fontWeight="semibold"
                style={{ marginBottom: "10px", width: "100%" }}
              />
              <Input
                py="2"
                isRequired
                type="password"
                placeholder="New Password"
                label="New Password"
                borderRadius="4"
                labelColor="#9ca3af"
                labelBGColor={colorModeValue}
                value={newPassword}
                onChangeText={(txt) => setNewPassword(txt)}
                fontWeight="semibold"
                style={{ marginBottom: "10px", width: "100%" }}
              />
              <Input
                py="2"
                isRequired
                type="password"
                placeholder="Confirm Password"
                label="Confirm Password"
                borderRadius="4"
                labelColor="#9ca3af"
                labelBGColor={colorModeValue}
                value={confirmPassword}
                onChangeText={(txt) => setConfirmPassword(txt)}
                fontWeight="semibold"
                style={{ marginBottom: "10px", width: "100%" }}
              />
              {validationError && (
                <Text style={{ color: "red", marginBottom: "10px" }}>
                  {validationError}
                </Text>
              )}
              <Button
                size="sm"
                onPress={handlePasswordUpdate}
                disabled={isUpdateDisabled}
                style={{
                  backgroundColor: isUpdateDisabled ? "#d3d3d3" : "#3182CE",
                  width: "100%",
                }}
              >
                Update Password
              </Button>
              <Button
                size="sm"
                onPress={handleCancelPasswordChange}
                style={{ width: "100%", marginTop: "10px" }}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
        <div
          style={{ margin: "0 auto", marginBottom: "20px", maxWidth: "300px" }}
        >
          <Button size="sm" onPress={() => setShowDeleteAccount(true)}>
            Delete Piatto Account
          </Button>
        </div>
      </div>
      {/* warning box 1*/}
      <Modal
        isOpen={showDeleteAccount}
        onClose={() => setShowDeleteAccount(false)}
        size={"xl"}
      >
        <Modal.Content maxH="212">
          <Modal.CloseButton />
          <Modal.Header>Warning!</Modal.Header>
          <Modal.Body>
            <ScrollView>
              <Text>
                Are you sure you want to delete your Piatto account? Deleting
                this account will delete all businesses maintained by this
                account! It will be a BIG pain to re-instate if you change your
                mind.
              </Text>
            </ScrollView>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  setShowDeleteAccount(false);
                }}
              >
                Cancel
              </Button>
              {/* warning box 2*/}
              <Popover
                trigger={(triggerProps) => {
                  return (
                    <Button
                      size="sm"
                      {...triggerProps}
                      colorScheme="danger"
                      onPress={() => setIsOpen(true)}
                    >
                      Yes
                    </Button>
                  );
                }}
                isOpen={isOpen}
              >
                <Popover.Content accessibilityLabel="Delete Customerd" w="56">
                  <Popover.Arrow />
                  <Popover.CloseButton onPress={() => setIsOpen(false)} />
                  <Popover.Header>Delete Account</Popover.Header>
                  <Popover.Body>
                    This account will no longer be available. While we will
                    maintain records for up-to 7 days, the businesses maintained
                    by this account will no longer be visible on Piatto and you
                    will not be able to log-in using this account. You will need
                    to call us to reinstate your account. Been fun while it
                    lasted!
                  </Popover.Body>
                  <Popover.Footer justifyContent="flex-end">
                    <Button.Group space={2}>
                      <Button
                        colorScheme="coolGray"
                        variant="ghost"
                        onPress={cancelDeleteAccount}
                      >
                        Cancel
                      </Button>
                      <Button
                        colorScheme="danger"
                        onPress={() => setIsOpen(false)}
                      >
                        Delete
                      </Button>
                    </Button.Group>
                  </Popover.Footer>
                </Popover.Content>
              </Popover>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

export default compose(connect(mapStateToProps))(UserAccountDetails);
