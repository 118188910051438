import Joyride, { CallBackProps } from "react-joyride";
import { Outlet, useHistory, useNavigate } from "react-router-dom";
import { useMount } from "react-use";
// import { Page, Paragraph, theme } from '@gilbarbara/components';

import { useAppContext } from "../context";
// import Header from './Header';

export default function MultiRouteWrapper() {
  const {
    setState,
    state: { run, stepIndex, steps },
  } = useAppContext();
  //   const navigate = useNavigate();
  const history = useHistory();

  useMount(() => {
    setState({
      steps: [
        {
          target: "#routeA",
          content: (
            <>
              <div>
                Click here to edit your Piatto web account details, change your
                email address or password.
              </div>
            </>
          ),
          data: {
            next: "/Home?step=1",
          },
          disableBeacon: true,
        },
        {
          target: "#routeB",
          content: (
            <>
              <div>
                Click here to Logout of this web app. The venue will remain
                visible on Piatto and will continue to receive orders so its
                advised against. You do not need to logout to protect security.
              </div>
            </>
          ),
          data: {
            previous: "/Home?firstLogin=true",
            next: "/Home?step=2",
          },
        },
        {
          target: "#routeC",
          content: (
            <>
              <div>
                Click here to Add Your Business to the Piatto platform, make it
                visible to the public and allow for remote orders processed via
                Piatto.
              </div>
            </>
          ),
          data: {
            previous: "/Home?step=1",
            next: "/Home",
          },
        },
        {
          target: "#routeD",
          content: (
            <div>
              This is the table number or location where the order was submitted
              from, where the customer would like to receive and enjoy their
              order. Remember 101 is front entrance way.
            </div>
          ),
          data: {
            previous: "/Home",
            next: "/Order",
          },
        },
        {
          target: "#routeE",
          content: (
            <div>
              This is the name of the dish being ordered, as it is displayed in
              Piatto.
            </div>
          ),
          data: {
            previous: "/Home",
            next: "/Order",
          },
        },
      ],
    });
  });

  const handleCallback = (data) => {
    const {
      action,
      index,
      step: {
        data: { next, previous },
      },
      type,
    } = data;
    const isPreviousAction = action === "prev";
    if (type === "step:after") {
      if (index < 2) {
        setState({ run: false });
        history.push(isPreviousAction && previous ? previous : next);
      }
      console.log('index',index)
      if (index === 2) {
        if (isPreviousAction && previous) {
          setState({ run: false });
          history.push(previous);
        } else {
          setState({ run: false, stepIndex: 0, tourActive: false });
        }
      }
      if(index ===3){
        console.log('run 123123123here')
        setState({ run: false });
        history.push('anUvHZaW5vWvLScFVD2f');
      }
    }
  };

  return (
    <>
      <Joyride
        callback={handleCallback}
        continuous
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        styles={{
          options: {
            // arrowColor: theme.black,
            // backgroundColor: theme.black,
            // primaryColor: theme.colors.purple,
            // textColor: theme.white,

            arrowColor: "#ffebee",
            backgroundColor: "rgba(255,255,255,1)",
            primaryColor: "rgba(255,0,0,1)",
            textColor: "rgba(0,0,0,1)",
            textFont: "Inclusive Sans, sans-serif",
          },
        }}
      />
    </>
  );
}
