import styled from "styled-components";

export const ImageOptionsWrapper = styled("div")`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: space-between;
  padding-right: 0.5px;
  position: absolute;
  right: 0.5rem;
  top: 0.2rem;
  width: 3rem;
`;
