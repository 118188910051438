import {VStack,Center,Text,View,Spinner,Box, Pressable,HStack,Button} from 'native-base'
import { isLoaded, useFirestoreConnect, } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import React from 'react';
import {
    getFirestore,
  } from "redux-firestore";
import { DeleteTables, SetTableBiz } from '../Redux/actions';
import AddTableModal from '../components/AddTableModal';
import TableDetailsModal from '../components/TableDetailsModel';
import {  useHistory } from "react-router-dom";
import WindowQrComponent from '../components/assetComponent/windowsQr';
import { SERVICE_QR_ID, TABLE_QR_ID, WINDOW_QR_ID, downloadQr } from '../utils/qr_util';
import ServiceDeskQrComponent from '../components/assetComponent/servicedeskQr';
import TableQRComponent from '../components/assetComponent/tableQr';




const BussinessPage =(props)=>{
    const id = props.match.params.id;
    const history = useHistory();

    const[Tables,setTables] = React.useState([]);
    const[Categories,setCategories] = React.useState([])
    const firestore = getFirestore()
    const [AddTable,setAddTable] = React.useState(false)
    const [detailTable,setDetailTable] = React.useState()


    React.useEffect(()=>{

        if(id){
    
          const unsubscribe = firestore.collection('Business')
          .doc(`${id}`)
          .collection('Tables')
          .onSnapshot((snapshot) => {
            // console.log(snapshot.data())
            const tables=[]
            const categories =[] // for seperate list
          
            snapshot.docs.forEach((doc) => {
                console.log('doc',doc.data())
                let data = {...doc.data(),id:doc.id}

                if(data['category']){
                    let tableCategory = tables.find(t=>t['category']===data['category'])
                    if(tableCategory){
                        tableCategory['tables'].push(data)
                    }else{
                        // tabale catefory was not made
                        tables.push(
                            {
                                category:data['category'],
                                tables:[data]
                            }
                        )
                        categories.push(data['category'])
                    }
                }
              

            });
            setTables(tables)
            setCategories(categories)
            setAddTable(false)

            console.log(snapshot.docs.data())
          });
          return () => {
            unsubscribe();
          };
        }
      
      },[id])






    

    useFirestoreConnect([
        {
          collection: "Business",
          where: [["bizId", "==", `${id}`]],
          orderBy: [["timestamp", "asc"]],
        },
      ]);
      const SingleBusiness = useSelector(
        ({ firestore: { ordered } }) => ordered.Business
      );
      console.log('id',id,SingleBusiness)




      const handleAddTables=()=>{
            setAddTable(true)
      }

      const handleDownloadQr=(table)=>{
        
        downloadQr(`${TABLE_QR_ID}-${table['tableNo']}`)
      }

      const handleReceptionQr =()=>{
     
        downloadQr(SERVICE_QR_ID)

            
      }
      const handleWindowQr =()=>{
        downloadQr(WINDOW_QR_ID)
      }


      const addEditTableToFB =(tables)=>{
            SetTableBiz(id,tables)
      }
      const addTablesNumber =(numbers,category)=>{
        console.log('tables',numbers,category)
        let tables =[]
        for (let tb of numbers){
            tables.push(
                {
                tableNo:tb,
                category:category
                }
            )
        }
        addEditTableToFB(tables)
        setAddTable(false)

      }

      const saveEditTable=(table)=>{
            addEditTableToFB([table])
            setDetailTable(undefined)

      }

      const deleteTables=(tables)=>{
          DeleteTables(id,tables)
      }




      const tablesComponent =(tables,category)=>{

        console.log('table compnent',tables)


        return(
            <Box>
                <Text>
                    {category}
                </Text>
                <Box flexDir={'row'} flexWrap={'wrap'} m={2}>
                {
                   ( tables.tables??[]).map(
                        t=>{
                            return(
                                <Pressable key={t.tableNo} onPress={()=>{
                                    setDetailTable(t)
                                }} >
                                <Center height={'13vh'} width={'13vh'} borderWidth={2} borderColor={'green.500'} >
                                    <Text>
                                        TABLE {t.tableNo}
                                    </Text>

                                    </Center>
                        
                                </Pressable>
                            )
                        }
                    )
                }

                 </Box>
               
                
            </Box>
        )
      }

      

    
      if ( isLoaded(SingleBusiness)) {
        const bizName = SingleBusiness[Object.keys(SingleBusiness)[0]].bizName
        return(
            <VStack
            flex="1"
            _light={{ bg: "white" }}
            _dark={{ bg: "coolGray.800" }}
            space="3"
            justifyContent="space-between"
          >
            <Center w={"100%"}>
              <Text color={"white"} position={"absolute"} zIndex={99999} top={-50}>
                {SingleBusiness[Object.keys(SingleBusiness)[0]].bizName}
              </Text>
              <Button mb={1} onPress={() => history.goBack()} w={"100%"}>
            Back to Order Page 
          </Button>
    
              <VStack maxW={'3/4'}  marginTop={10}  alignContent={'flex-start'} width={'100%'}  >
    
              <HStack alignItems={'center'} m={2} marginTop={10}>
              <Text fontSize={'lg'}  >
                General QRs
                </Text>
              <Button size={'xs'} marginLeft={2} colorScheme={'green'} onPress={handleReceptionQr} >
                  Reception QR
             </Button>
             <Button size={'xs'} colorScheme={'orange'} marginLeft={2} onPress={handleWindowQr} >
                 window QR
             </Button>
           

              </HStack>
              
              

                

                <HStack alignItems={'center'} m={2} marginTop={10}>
                <Text fontSize={'lg'}>
                 Tables
              </Text>
              <Button size={'xs'} marginLeft={2} onPress={handleAddTables} >
                  Add +
             </Button>
             {/* <Button size={'xs'} colorScheme={'orange'} marginLeft={2} onPress={handleAddTables} >
                 Download QRs
             </Button> */}

              </HStack>
                {
                    Tables.map(
                        t=>{
                            return (
                                <React.Fragment key={t['category']}>
                                    {tablesComponent(t,t['category'])}
                                </React.Fragment>
                            )
                        }
                    )
                }
                {}

                 
          
    
              </VStack>
              
              </Center>
              <AddTableModal
               opened={AddTable}
                
                closeHandler={()=>{setAddTable(false)}} 
                categories={Categories} 
                onAdd={addTablesNumber}/>
                <TableDetailsModal
                 bizId={id}
                 bizName={bizName}
                 table={detailTable}
                 closeHandler={()=>{setDetailTable(undefined)}} 
                categories={Categories} onSave={saveEditTable}
                downloadQR={(table)=>{
                    handleDownloadQr(table)
                }}
                onDelete={(table)=>{
                    deleteTables([table])
                    setDetailTable(undefined)
                }}
                />
            <WindowQrComponent bizId={id} />
            <ServiceDeskQrComponent bizId={id}/>
            
    
         </VStack>
        )
      
    }else{
        return (
            <View>
              <Center>
                <Spinner size={"lg"} />
              </Center>
            </View>
          );

    }
    

   
}

export default BussinessPage;