import {
  Button,
  Center,
  FormControl,
  HStack,
  Input,
  Select,
  Text,
  View,
  VStack,
  Spinner,
  Box,
  Modal,
  Radio,
  Alert,
  IconButton,
  CloseIcon,
  CheckIcon,
} from "native-base";
import React, { useMemo, useState, useEffect } from "react";
import TimeInput from "react-time-picker-input";
import Notiflix from "notiflix";

import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AddBiz, AddGMB } from "../Redux/actions";
import MultiImageInput from "../components/multi-image-input";
import { getGeocode } from "use-places-autocomplete";
import { Multiselect } from "multiselect-react-dropdown";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";

const AddBusiness = ({ auth }) => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  // need to add
  const [bizAddress, setbizAddress] = useState({ address: "" });
  const [bizLunchOpen, setBizLunchOpen] = useState("12:00"); //
  const [bizLunchClose, setBizLunchClose] = useState("15:00");
  const [bizDinnerOpen, setBizDinnerOpen] = useState("06:00");
  const [bizDinnerClose, setBizDinnerClose] = useState("21:30");

  const [bizName, setBizName] = useState(""); //
  const [bizType, setBType] = useState(""); /// bussinees types \

  //Nuber of seats
  const [bizInsideLowSeatNo, setBizInsideLowSeatNo] = useState("");
  const [bizInsideHighSeatNo, setBizInsideHighSeatNo] = useState("");
  const [bizOutsideLowSeatNo, setBizOutsideLowSeatNo] = useState("");
  const [bizOutsideHighSeatNo, setBizOutsideHighSeatNo] = useState("");
  // Number of tables
  const [bizInsideLowTableNo, setBizInsideLowTableNo] = useState("");
  const [bizInsideHighTableNo, setBizInsideHighTableNo] = useState("");
  const [bizOutsideLowTableNo, setBizOutsideLowTableNo] = useState("");
  const [bizOutsideHighTableNo, setBizOutsideHighTableNo] = useState("");
  //Open and Close
  const [bizMondayOpen, setBizMondayOpen] = useState("08:00"); //
  const [bizMondayClose, setBizMondayClose] = useState("21:30");
  const [bizTuesdayOpen, setBizTuesdayOpen] = useState("08:00");
  const [bizTuesdayClose, setBizTuesdayClose] = useState("21:30");
  const [bizWednesdayOpen, setBizWednesdayOpen] = useState("08:00");
  const [bizWednesdayClose, setbizWednesdayClose] = useState("21:30");
  const [bizThursdayOpen, setBizThursdayOpen] = useState("08:00");
  const [bizThursdayClose, setBizThursdayClose] = useState("21:30");
  const [bizFridayOpen, setBizFridayOpen] = useState("08:00");
  const [bizFridayClose, setbizFridayClose] = useState("21:30");
  const [bizSaturdayOpen, setbizSaturdayOpen] = useState("08:00");
  const [bizSaturdayClose, setBizSaturdayClose] = useState("21:30");
  const [bizSundayOpen, setBizSundayOpen] = useState("08:00");
  const [bizSundayClose, setBizSundayClose] = useState("21:30");
  const [BreakfastS, setBreakfastS] = useState("08:00");
  const [BreakfastF, setBreakfastF] = useState("10:00");

  const [wizard, Setwizard] = useState(0);

  const [bizPhone, setBizPhone] = useState("");
  const [bizEmail, setBizEmail] = useState("");
  const [bizWeb, setBizWeb] = useState("");

  const [bizOrderPhone, setBizOrderPhone] = useState("");
  const [bizOrderEmail, setBizOrderEmail] = useState("");

  const center = useMemo(() => ({ lat: 43.45, lng: -80.49 }), []);
  const [selected, setSelected] = useState(null);
  const [images, setImages] = React.useState({});

  const [bizCuisines, setBizCusines] = React.useState([]);
  const [userGoogleData, setUserGoogleData] = useState([]);
  const [loadingGoogleData, setLoadingGoogleData] = useState(false);
  const [showConnectGMB, setShowConnectGMB] = useState(false);
  const [isMobile, setIsMobile] = useState(null);
  const [failConnectGMB, setFailConnectGMB] = useState(false);
  const [selectValue, setSelectValue] = useState(null);
  const [selectGMB, setSelectGMB] = useState(null);
  const [myBIZ, setMyBIZ] = useState([]);

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  // useEffect(() => {
  //   //Gets the passed parameter from location.state
  //   const userGoogleData = location.state.userGoogleData;
  //   setUserGoogleData(userGoogleData);
  // }, [userGoogleData]);

  // getting cuisines
  useFirestoreConnect([
    {
      collection: "Cuisine",
    },
  ]);
  const Cuisine = useSelector(({ firestore: { ordered } }) => ordered.Cuisine);
  // getting busoiness types
  useFirestoreConnect([
    {
      collection: "Type",
    },
  ]);
  useFirestoreConnect([
    {
      collection: "Business",
      where: [["uid", "==", auth.uid ? auth.uid : null]],
      orderBy: [["timestamp", "asc"]],
    },
  ]);
  const BizTypes = useSelector(({ firestore: { ordered } }) => ordered.Type);
  const BIZ = useSelector(({ firestore: { ordered } }) => ordered.Business);

  const handleLatLang = async (locationData, address) => {
    let add = address;
    setSelected(locationData);

    const r = await getGeocode({ location: locationData });
    console.log(r);
    address = r[0].formatted_address;

    let geoAddres = r[0].address_components;

    const suburb =
      geoAddres.find((component) => component.types.includes("locality"))
        ?.long_name || "";
    const postalAddress =
      geoAddres.find((component) => component.types.includes("postal_code"))
        ?.long_name || "";
    const bizAdmin2 =
      geoAddres.find((component) =>
        component.types.includes("administrative_area_level_2")
      )?.long_name || "";
    const state =
      geoAddres.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name || "";
    const street =
      geoAddres.find((component) => component.types.includes("route"))
        ?.long_name || "";
    const streetNumber =
      geoAddres.find((component) => component.types.includes("street_number"))
        ?.long_name || "";
    const country =
      geoAddres.find((component) => component.types.includes("country"))
        ?.long_name || "";

    add = {
      bizAddress: address,
      bizSuburb: suburb,
      bizPostalAddress: postalAddress,
      bizAdmin2, // for preccint
      bizState: state,
      bizStreet: street,
      bizStreetNumber: streetNumber,
      bizCountry: country,
    };

    setbizAddress(add);

    console.log(add);
  };

  const crop = {
    unit: "%",
    aspect: 4 / 3,
    width: "100",
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBkvAMjYkt2C8-lccAhAgxEEKh3vSHDE24",
    libraries: ["places"],
  });

  // start to get google Oauth2, get google signIn page at first
  const handleConnectGMB = () => {
    fetch("https://australia-southeast1-piatto-97499.cloudfunctions.net/user/landing", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from server:", data);
        // server will use keys like clientId, client secret to create an url to sign in with user google account
        window.location.href = data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const url = window.location.href;

  useEffect(() => {
    var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
    if (isMobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });
  useEffect(() => {
    if (url.includes("code=")) {
      setLoadingGoogleData(true);
    }
    // Check that the URL contains the required parameters
    const fetchData = async () => {
      if (url.includes("code=")) {
        setLoadingGoogleData(true);
        const paramsString = url.split("?")[1];
        const params = new URLSearchParams(paramsString);
        // Get the value of the parameter named "code"
        const code = params.get("code");

        try {
          const response = await fetch("https://australia-southeast1-piatto-97499.cloudfunctions.net/user/code", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: code,
            }),
          });

          if (response.ok) {
            const responseData = await response.json();
            //get user google my business data
            if (
              !responseData.projects ||
              responseData.projects == "undefined"
            ) {
              setFailConnectGMB(true);
              setTimeout(() => {
                setFailConnectGMB(false);
              }, 2000);
            } else {
              const dataGMB = responseData.projects;
              BIZ.forEach((bizObject) => {
                if (bizObject.selectGMB) {
                  const selectGMBProjectId = bizObject.selectGMB.projectId;
                  const matchingDataGMB = dataGMB.find(
                    (dataGMBObject) =>
                      dataGMBObject.projectId === selectGMBProjectId
                  );
                  if (matchingDataGMB) {
                    const newObj = { ...matchingDataGMB, disable: true };
                    const index = dataGMB.findIndex(
                      (obj) => obj.projectId === matchingDataGMB.projectId
                    );
                    dataGMB[index] = newObj;
                  }
                }
              });
              setUserGoogleData(responseData.projects);
              // setSelectValue(dataGMB[0].projectId);
              // setBizName(dataGMB[0].name);
              setLoadingGoogleData(false);
              setShowConnectGMB(true);
            }
          } else {
            console.error("Error fetching code:", response.statusText);
            setLoadingGoogleData(false);
            return;
          }
        } catch (error) {
          setLoadingGoogleData(false);
          console.error("Error fetching code:", error);
        }
      }
    };
    if (BIZ && BIZ.length > 0) {
      fetchData();
    }
    // Call the async function immediately
  }, [url, BIZ]);

  if (!auth.uid && !url.includes("code=")) return <Redirect to="/" />;

  return (
    <VStack
      flex="1"
      px="6"
      py="9"
      _light={{ bg: "white" }}
      _dark={{ bg: "coolGray.800" }}
      space="3"
      justifyContent="space-between"
    >
      <Center>
        <VStack textAlign={"center"} w={"70%"} space={4}>
          {wizard === 0 && (
            <>
              {failConnectGMB && (
                <Alert w="100%" status={"error"}>
                  <VStack space={2} flexShrink={1} w="100%">
                    <HStack
                      flexShrink={1}
                      space={2}
                      justifyContent="space-between"
                    >
                      <HStack space={2} flexShrink={1}>
                        <Alert.Icon mt="1" />
                        <Text fontSize="md" color="coolGray.800">
                          There is poor internet connection, please reconnect
                          with google my business.
                        </Text>
                      </HStack>
                      <IconButton
                        variant="unstyled"
                        _focus={{
                          borderWidth: 0,
                        }}
                        icon={<CloseIcon size="3" />}
                        _icon={{
                          color: "coolGray.600",
                        }}
                      />
                    </HStack>
                  </VStack>
                </Alert>
              )}
              <View>
                <Text
                  fontSize={"3xl"}
                  fontWeight="bold"
                  fontFamily="Inclusive Sans, sans-serif"
                >
                  Add Business
                </Text>

                <Text
                  mb={10}
                  fontSize={"lg"}
                  fontFamily="Inclusive Sans, sans-serif"
                >
                  Please input the following details about your business so we
                  may enter your business into the Piatto database. Your
                  business will quickly become visible in the Piatto app!
                </Text>
                <Button
                  justifyContent={"center"}
                  size="lg"
                  onPress={handleConnectGMB}
                  fontFamily="Inclusive Sans, sans-serif"
                >
                  <span className="inclusive-font-span white">
                    Connect with google my business
                  </span>
                </Button>

                {loadingGoogleData && <Spinner color="emerald.500" />}
                <Select
                  placeholder="Select after connect with Google My Business"
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size="5" />,
                    startIcon: <Spinner color="emerald.500" />,
                  }}
                  mt={2}
                  mb={10}
                  h={10}
                  selectedValue={selectValue}
                  onValueChange={(value) => {
                    setSelectValue(value);
                    const selectedValue = userGoogleData.find(
                      (item) => item.projectId === value
                    );
                    setBizName(selectedValue.name);
                    setSelectGMB(selectedValue);
                  }}
                  style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                  placeholderTextFontFamily="Inclusive Sans, sans-serif"
                >
                  {userGoogleData &&
                    userGoogleData.length > 0 &&
                    userGoogleData.map((item) => (
                      <Select.Item
                        label={item.name}
                        value={item.projectId}
                        isDisabled={item.disable ? true : false}
                      />
                    ))}
                </Select>
                <HStack justifyContent={"center"} space={2} flexWrap={"wrap"}>
                  <FormControl w={"48%"}>
                    <FormControl.Label>
                      <span className="inclusive-font-span gray">
                        {" "}
                        Name of your business
                      </span>
                    </FormControl.Label>

                    <FormControl>
                      <Input
                        maxLength={20}
                        onChangeText={(txt) => setBizName(txt)}
                        value={bizName}
                        autoComplete="off"
                        placeholder="Name of your business"
                        style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                        placeholderTextFontFamily="Inclusive Sans, sans-serif"
                      />
                    </FormControl>
                  </FormControl>

                  <FormControl w={"48%"}>
                    <FormControl.Label>
                      <span className="inclusive-font-span gray">
                        {" "}
                        Your business type
                      </span>
                    </FormControl.Label>
                    <Select
                      defaultValue={bizType["typeName"]}
                      onValueChange={(itemValue) => {
                        let bizTy = BizTypes.find(
                          (typ) => typ["typeName"] === itemValue
                        );
                        setBType(bizTy);
                      }}
                      accessibilityLabel="Select your Your business type"
                      placeholder="Select your Your business type"
                      style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                      placeholderTextFontFamily="Inclusive Sans, sans-serif"
                    >
                      {BizTypes &&
                        BizTypes.map((biz_) => (
                          <Select.Item
                            key={biz_["id"]}
                            label={biz_["typeName"]}
                            value={biz_["typeName"]}
                          />
                        ))}
                      {/* <Select.Item label="Restaurant" value="Restaurant" />
                    <Select.Item label="Bar" value="Bar" />
                    <Select.Item label="Take Away" value="Take Away" />
                    <Select.Item label="Pop Up" value="Pop Up" />
                    <Select.Item label="Food Truck" value="Food Truck" /> */}
                    </Select>
                  </FormControl>
                </HStack>
              </View>
            </>
          )}

          {wizard === 1 && (
            <View>
              <Text
                fontSize={"2xl"}
                fontWeight="bold"
                fontFamily="Inclusive Sans, sans-serif"
              >
                Business Location
              </Text>
              <Text
                mb={5}
                fontSize={"lg"}
                fontFamily="Inclusive Sans, sans-serif"
              >
                Please inform us of your Business's location. The longitude and
                latitude values of your location will automatically be generated
                and inputted into our database along with your submission of
                this form.
              </Text>

              <FormControl>
                <FormControl.Label
                  _text={{
                    fontFamily: "Inclusive Sans, sans-serif",
                  }}
                >
                  Address
                </FormControl.Label>
              </FormControl>
              <div className="places-container" style={{ width: "98%" }}>
                <PlacesAutocomplete setSelected={handleLatLang} />
              </div>
              {isLoaded && selected ? (
                <>
                  <GoogleMap
                    zoom={18}
                    center={selected ? selected : center}
                    onClick={(e) => {
                      const data = {
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng(),
                      };
                      handleLatLang(data, null);
                    }}
                    mapContainerClassName="map-container"
                  >
                    {selected && <Marker position={selected} />}
                  </GoogleMap>
                </>
              ) : (
                <></>
              )}
            </View>
          )}

          {wizard === 2 && (
            <View>
              <Text
                fontSize={"2xl"}
                fontWeight="bold"
                fontFamily="Inclusive Sans, sans-serif"
              >
                Business Seating
              </Text>
              <Text
                mb={10}
                fontSize={"lg"}
                fontFamily="Inclusive Sans, sans-serif"
              >
                Please inform us of the seating at your establishment, including
                the number of High and Low Tables, the number of tables both
                inside and outside, along with the number of seats at each
                category of table.
              </Text>
              <HStack justifyContent={"center"} space={2} flexWrap={"wrap"}>
                <FormControl w={"23%"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Total number of seats at inside low tables:
                  </FormControl.Label>
                  <Input
                    maxLength={3}
                    autoComplete="off"
                    value={bizInsideLowSeatNo}
                    onChangeText={(t) => setBizInsideLowSeatNo(t)}
                    placeholder="inside low tables"
                    style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                  />
                </FormControl>
                <FormControl w={"23%"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Total number of seats at inside high tables:
                  </FormControl.Label>
                  <Input
                    maxLength={3}
                    autoComplete="off"
                    value={bizInsideHighSeatNo}
                    onChangeText={(t) => setBizInsideHighSeatNo(t)}
                    placeholder="inside high tables"
                    style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                  />
                </FormControl>
                <FormControl w={"23%"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Total number of seats at outside low tables:
                  </FormControl.Label>
                  <Input
                    maxLength={3}
                    autoComplete="off"
                    value={bizOutsideLowSeatNo}
                    onChangeText={(t) => setBizOutsideLowSeatNo(t)}
                    placeholder="outside low tables"
                    style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                  />
                </FormControl>
                <FormControl w={"23%"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Total number of seats at outside high tables:
                  </FormControl.Label>
                  <Input
                    maxLength={3}
                    autoComplete="off"
                    value={bizOutsideHighSeatNo}
                    onChangeText={(t) => setBizOutsideHighSeatNo(t)}
                    placeholder="outside high tables"
                    style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                  />
                </FormControl>
                <FormControl w={"23%"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Total number of inside low tables:
                  </FormControl.Label>
                  <Input
                    maxLength={3}
                    autoComplete="off"
                    value={bizInsideLowTableNo}
                    onChangeText={(t) => setBizInsideLowTableNo(t)}
                    placeholder="inside low tables"
                    style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                  />
                </FormControl>
                <FormControl w={"23%"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Total number of inside high tables:
                  </FormControl.Label>
                  <Input
                    maxLength={3}
                    autoComplete="off"
                    value={bizInsideHighTableNo}
                    onChangeText={(t) => setBizInsideHighTableNo(t)}
                    placeholder="inside high tables"
                    style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                  />
                </FormControl>
                <FormControl w={"23%"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Total number of outside low tables:
                  </FormControl.Label>
                  <Input
                    maxLength={3}
                    autoComplete="off"
                    value={bizOutsideLowTableNo}
                    onChangeText={(t) => setBizOutsideLowTableNo(t)}
                    placeholder="outside low tables"
                    style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                  />
                </FormControl>
                <FormControl w={"23%"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Total number of outside high table:
                  </FormControl.Label>
                  <Input
                    maxLength={3}
                    autoComplete="off"
                    value={bizOutsideHighTableNo}
                    onChangeText={(t) => setBizOutsideHighTableNo(t)}
                    placeholder="outside high table"
                    style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                  />
                </FormControl>
              </HStack>
            </View>
          )}

          {wizard === 3 && (
            <View>
              <Text
                fontSize={"2xl"}
                fontWeight="bold"
                fontFamily="Inclusive Sans, sans-serif"
              >
                Trading Hours
              </Text>
              <Text
                mb={10}
                fontSize={"lg"}
                fontFamily="Inclusive Sans, sans-serif"
              >
                Please indicate the opening and closing times for each day of
                the week that you are operating. Please also inform us of the
                Breakfast, Lunch and Dinner start/end times. They should be
                recorded in 24 hour time.
              </Text>
              <HStack
                zIndex={10}
                justifyContent={"center"}
                space={10}
                flexWrap={"wrap"}
              >
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Opening time on Monday:
                  </FormControl.Label>
                  <TimeInput
                    value={bizMondayOpen}
                    fullTimeDropdown
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                    fontFamily="Inclusive Sans, sans-serif"
                    style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                    onChange={(dateString) => setBizMondayOpen(dateString)}
                  />
                </VStack>
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Closing time on Monday:
                  </FormControl.Label>
                  <TimeInput
                    value={bizMondayClose}
                    fullTimeDropdown
                    onChange={(dateString) => setBizMondayClose(dateString)}
                  />
                </VStack>
              </HStack>
              <HStack
                zIndex={9}
                justifyContent={"center"}
                space={10}
                flexWrap={"wrap"}
              >
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Opening time on Tuesday:
                  </FormControl.Label>
                  <TimeInput
                    value={bizTuesdayOpen}
                    fullTimeDropdown
                    onChange={(dateString) => setBizTuesdayOpen(dateString)}
                  />
                </VStack>
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Closing time on Tuesday:
                  </FormControl.Label>
                  <TimeInput
                    value={bizTuesdayClose}
                    fullTimeDropdown
                    onChange={(dateString) => setBizTuesdayClose(dateString)}
                  />
                </VStack>
              </HStack>
              <HStack
                zIndex={8}
                justifyContent={"center"}
                space={10}
                flexWrap={"wrap"}
              >
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Opening time on Wednesday:
                  </FormControl.Label>
                  <TimeInput
                    value={bizWednesdayOpen}
                    fullTimeDropdown
                    onChange={(dateString) => setBizWednesdayOpen(dateString)}
                  />
                </VStack>
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Closing time on Wednesday:
                  </FormControl.Label>
                  <TimeInput
                    value={bizWednesdayClose}
                    fullTimeDropdown
                    onChange={(dateString) => setbizWednesdayClose(dateString)}
                  />
                </VStack>
              </HStack>
              <HStack
                zIndex={7}
                justifyContent={"center"}
                space={10}
                flexWrap={"wrap"}
              >
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Opening time on Thursday:
                  </FormControl.Label>
                  <TimeInput
                    value={bizThursdayOpen}
                    fullTimeDropdown
                    onChange={(dateString) => setBizThursdayOpen(dateString)}
                  />
                </VStack>
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Closing time on Thursday:
                  </FormControl.Label>
                  <TimeInput
                    value={bizThursdayClose}
                    fullTimeDropdown
                    onChange={(dateString) => setBizThursdayClose(dateString)}
                  />
                </VStack>
              </HStack>
              <HStack
                zIndex={6}
                justifyContent={"center"}
                space={10}
                flexWrap={"wrap"}
              >
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Opening time on Friday:
                  </FormControl.Label>
                  <TimeInput
                    value={bizFridayOpen}
                    fullTimeDropdown
                    onChange={(dateString) => setBizFridayOpen(dateString)}
                  />
                </VStack>
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Closing time on Friday:
                  </FormControl.Label>
                  <TimeInput
                    value={bizFridayClose}
                    fullTimeDropdown
                    onChange={(dateString) => setbizFridayClose(dateString)}
                  />
                </VStack>
              </HStack>
              <HStack
                zIndex={5}
                justifyContent={"center"}
                space={10}
                flexWrap={"wrap"}
              >
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Opening time on Saturday:
                  </FormControl.Label>
                  <TimeInput
                    value={bizSaturdayOpen}
                    fullTimeDropdown
                    onChange={(dateString) => setbizSaturdayOpen(dateString)}
                  />
                </VStack>
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Closing time on Saturday:
                  </FormControl.Label>
                  <TimeInput
                    value={bizSaturdayClose}
                    fullTimeDropdown
                    onChange={(dateString) => setBizSaturdayClose(dateString)}
                  />
                </VStack>
              </HStack>
              <HStack
                zIndex={4}
                justifyContent={"center"}
                space={10}
                flexWrap={"wrap"}
              >
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Opening time on Sunday:
                  </FormControl.Label>
                  <TimeInput
                    value={bizSundayOpen}
                    fullTimeDropdown
                    onChange={(dateString) => setBizSundayOpen(dateString)}
                  />
                </VStack>
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Closing time on Sunday:
                  </FormControl.Label>
                  <TimeInput
                    value={bizSundayClose}
                    fullTimeDropdown
                    onChange={(dateString) => setBizSundayClose(dateString)}
                  />
                </VStack>
              </HStack>
              <HStack
                zIndex={3}
                justifyContent={"center"}
                space={10}
                flexWrap={"wrap"}
              >
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Breakfast start time:
                  </FormControl.Label>
                  <TimeInput
                    value={BreakfastS}
                    fullTimeDropdown
                    onChange={(dateString) => setBreakfastS(dateString)}
                  />
                </VStack>
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Breakfast finish time:
                  </FormControl.Label>
                  <TimeInput
                    value={BreakfastF}
                    fullTimeDropdown
                    onChange={(dateString) => setBreakfastF(dateString)}
                  />
                </VStack>
              </HStack>
              <HStack
                zIndex={2}
                justifyContent={"center"}
                space={10}
                flexWrap={"wrap"}
              >
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Lunch start time:
                  </FormControl.Label>
                  <TimeInput
                    value={bizLunchOpen}
                    fullTimeDropdown
                    onChange={(dateString) => setBizLunchOpen(dateString)}
                  />
                </VStack>
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Lunch finish time:
                  </FormControl.Label>
                  <TimeInput
                    value={bizLunchClose}
                    fullTimeDropdown
                    onChange={(dateString) => setBizLunchClose(dateString)}
                  />
                </VStack>
              </HStack>
              <HStack
                zIndex={1}
                justifyContent={"center"}
                space={10}
                flexWrap={"wrap"}
              >
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Dinner start time:
                  </FormControl.Label>
                  <TimeInput
                    value={bizDinnerOpen}
                    fullTimeDropdown
                    onChange={(dateString) => setBizDinnerOpen(dateString)}
                  />
                </VStack>
                <VStack alignItems={"center"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Dinner finish time:
                  </FormControl.Label>
                  <TimeInput
                    value={bizDinnerClose}
                    fullTimeDropdown
                    onChange={(dateString) => setBizDinnerClose(dateString)}
                  />
                </VStack>
              </HStack>
            </View>
          )}

          {wizard === 4 && (
            <View zIndex={99999}>
              <Text
                mt={5}
                fontSize={"2xl"}
                fontWeight="bold"
                fontFamily="Inclusive Sans, sans-serif"
              >
                Business Picture
              </Text>

              <Text
                mb={10}
                fontSize={"lg"}
                fontFamily="Inclusive Sans, sans-serif"
              >
                Please upload your business picture
              </Text>
              <Text
                mb={10}
                fontSize={"lg"}
                fontFamily="Inclusive Sans, sans-serif"
              >
                Please ensure the first photo of your business shows the
                entirety of your business from the pavement/sidewalk, as
                somebody approaching your business from head on would see it.
                Preferably a photo depicting the physical entrance, to allow
                those with physical disabilities to plan ahead and know what to
                expect.
              </Text>
              <VStack zIndex={99999}>
                <MultiImageInput
                  images={images}
                  max={5}
                  setImages={setImages}
                  cropConfig={{ crop, ruleOfThirds: true }}
                />
              </VStack>

              <Text
                fontSize={"2xl"}
                fontWeight="bold"
                fontFamily="Inclusive Sans, sans-serif"
                mt={50}
              >
                Business Contact
              </Text>

              <Text
                mb={10}
                mt={10}
                fontSize={"lg"}
                fontFamily="Inclusive Sans, sans-serif"
              >
                Please inform us of your business contact details, along with a
                specific phone number and/or email address for ordering if one
                exists. We will not need these details to send orders to you, as
                you will have access to our Venue Dashboard upon submission of
                this form however it may help us to customise our service to
                you.
              </Text>
              <HStack justifyContent={"center"} space={2} flexWrap={"wrap"}>
                <FormControl w={"49%"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Phone number of business:
                  </FormControl.Label>

                  <Input
                    maxLength={12}
                    value={bizPhone}
                    onChangeText={(t) => setBizPhone(t)}
                    autoComplete="off"
                    placeholder="Phone number of business"
                    style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                  />
                </FormControl>
                <FormControl w={"49%"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Email address of business:
                  </FormControl.Label>

                  <Input
                    maxLength={25}
                    value={bizEmail}
                    onChangeText={(t) => setBizEmail(t)}
                    autoComplete="off"
                    placeholder="Email address of business"
                    style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                  />
                </FormControl>

                <FormControl w={"49%"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Website of business:
                  </FormControl.Label>
                  <Input
                    maxLength={25}
                    value={bizWeb}
                    onChangeText={(t) => setBizWeb(t)}
                    autoComplete="off"
                    placeholder="Website of business"
                    style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                  />
                </FormControl>
                <FormControl w={"49%"}>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Phone number for ordering at business:
                  </FormControl.Label>

                  <Input
                    maxLength={12}
                    value={bizOrderPhone}
                    onChangeText={(t) => setBizOrderPhone(t)}
                    autoComplete="off"
                    placeholder="Phone number for ordering at business"
                    style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                  />
                </FormControl>
                <FormControl>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Email address for sending orders to business:
                  </FormControl.Label>

                  <Input
                    maxLength={25}
                    value={bizOrderEmail}
                    onChangeText={(t) => setBizOrderEmail(t)}
                    autoComplete="off"
                    placeholder="Email address for sending orders to business"
                    style={{ fontFamily: "Inclusive Sans, sans-serif" }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                  />
                </FormControl>
                <FormControl>
                  <FormControl.Label
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                  >
                    Businees Cusines
                  </FormControl.Label>
                  <Multiselect
                    placeholder="Select Cusines"
                    isObject={false}
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={(list, item) => {
                      console.log(list);
                      setBizCusines(list);
                    }}
                    onSearch={function noRefCheck() {}}
                    onSelect={(list, item) => {
                      console.log(list);
                      setBizCusines(list);
                    }}
                    options={Cuisine ? Cuisine.map((c) => c["cuisName"]) : []}
                    fontFamily="Inclusive Sans, sans-serif"
                    _text={{
                      fontFamily: "Inclusive Sans, sans-serif",
                    }}
                    style={{
                      fontFamily: "Inclusive Sans, sans-serif",
                      placeholder: "Inclusive Sans, sans-serif",
                    }}
                    placeholderTextFontFamily="Inclusive Sans, sans-serif"
                  />
                </FormControl>
              </HStack>
            </View>
          )}
          {wizard < 4 && (
            <Center>
              <VStack w={"100%"} space={2}>
                <Button
                  onPress={() => {
                    if (wizard === 0) {
                      if (
                        bizName.length > 0 &&
                        bizType["typeName"].length > 1
                      ) {
                        Setwizard(wizard + 1);
                      } else {
                        Notiflix.Report.Warning(
                          "Warning",
                          `Please fill in all the required fields`,
                          "Close"
                        );
                      }
                    }
                    if (wizard === 1) {
                      if (selected) {
                        Setwizard(wizard + 1);
                        console.log(selected);
                      } else {
                        Notiflix.Report.Warning(
                          "Warning",
                          `Please enter your business address`,
                          "Close"
                        );
                      }
                    }
                    if (wizard === 2) {
                      if (
                        bizInsideLowSeatNo.length > 0 &&
                        bizInsideHighSeatNo.length > 0 &&
                        bizOutsideLowSeatNo.length > 0 &&
                        bizOutsideHighSeatNo.length > 0 &&
                        bizInsideLowTableNo.length > 0 &&
                        bizInsideHighTableNo.length > 0 &&
                        bizOutsideLowTableNo.length > 0 &&
                        bizOutsideHighTableNo.length > 0
                      ) {
                        Setwizard(wizard + 1);
                      } else {
                        Notiflix.Report.Warning(
                          "Warning",
                          `please fill all inputs`,
                          "Close"
                        );
                      }
                    }
                    if (wizard === 3) {
                      Setwizard(wizard + 1);
                    }
                  }}
                >
                  <span className="inclusive-font-span white"> Next</span>
                </Button>
                <Button
                  colorScheme="coolGray"
                  isDisabled={wizard < 1}
                  onPress={() => {
                    Setwizard(wizard - 1);
                  }}
                >
                  <span className="inclusive-font-span white"> Back</span>
                </Button>
              </VStack>
            </Center>
          )}

          {wizard === 4 && (
            <VStack>
              <Button
                mb={2}
                onPress={() => {
                  if (
                    bizPhone.length > 0 &&
                    bizEmail.length > 0 &&
                    bizWeb.length > 0 &&
                    bizOrderPhone.length > 0 &&
                    bizOrderEmail.length > 0 &&
                    Object.keys(images).length > 0
                  ) {
                    const BizInfo = {
                      bizName,
                      bizType,
                      bizInsideLowSeatNo,
                      bizInsideHighSeatNo,
                      bizOutsideLowSeatNo,
                      bizOutsideHighSeatNo,
                      bizInsideLowTableNo,
                      bizInsideHighTableNo,
                      bizOutsideLowTableNo,
                      bizOutsideHighTableNo,
                      bizMondayOpen,
                      bizMondayClose,
                      bizTuesdayOpen,
                      bizTuesdayClose,
                      bizWednesdayOpen,
                      bizWednesdayClose,
                      bizThursdayOpen,
                      bizThursdayClose,
                      bizFridayOpen,
                      bizFridayClose,
                      bizSaturdayOpen,
                      bizSaturdayClose,
                      bizSundayClose,
                      bizSundayOpen,
                      BreakfastS,
                      BreakfastF,
                      bizLunchOpen,
                      bizLunchClose,
                      bizDinnerOpen,
                      bizDinnerClose,
                      bizPhone,
                      bizEmail,
                      bizWeb,
                      bizOrderPhone,
                      bizOrderEmail,
                      bizLat: selected["lat"],
                      bizLon: selected["lng"],
                      images,
                      bizCuisines,
                      selectGMB,
                      ...bizAddress,
                    };
                    dispatch(AddBiz(BizInfo, history));
                  } else {
                    Notiflix.Report.Warning(
                      "Warning",
                      `please fill all Business Contacts information`,
                      "Close"
                    );
                  }
                }}
                colorScheme="danger"
                _text={{
                  fontFamily: "Inclusive Sans, sans-serif",
                }}
              >
                Submit
              </Button>
              <Button
                colorScheme="coolGray"
                mb={500}
                isDisabled={wizard < 1}
                onPress={() => {
                  Setwizard(wizard - 1);
                }}
                _text={{
                  fontFamily: "Inclusive Sans, sans-serif",
                }}
              >
                Back
              </Button>
            </VStack>
          )}
        </VStack>
      </Center>
    </VStack>
  );
};

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    auth: state.firebase.auth,
  };
};

export default compose(connect(mapStateToProps))(AddBusiness);
