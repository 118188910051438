

import { Box } from 'native-base'
import tableQr from '../../assets/table_qr.svg'
import QRCode from 'qrcode.react';
import { TABLE_QR_ID } from '../../utils/qr_util';
import { Typography } from '@mui/material';

const TableQRComponent =({bizId,bizName,tableId})=>{

  
    // 
    return(
        <div style={{height:'0px',width:'0px'}}  >
        <div id={`${TABLE_QR_ID}-${tableId}`}  style={{position:'relative',height:'303px',width:'303px',display:'none'}}>

        <img alt={'window qr'} src={tableQr}  />
        <Box position={'absolute'} style={{top:70,right:121}}>
        <QRCode fgColor={'#231F20'}  value={JSON.stringify({bizId:bizId,tableId:tableId})}  style={{ height:'160px',width:'160px'}}/>
        </Box>
        <Box position={'absolute'}  style={{top:220,right:20}}>
            <Typography textAlign={'center'} color={'#231F20'}  fontSize={`${tableId}`.length<3?60:45} width={70} fontFamily={'Merriweather'}>
            {tableId}
            </Typography>
        </Box>
        <Box justifyContent={'center'} position={'absolute'}  style={{top:230,left:20,maxWidth:180,height:70}}>
            <Typography color={'#231F20'} fontSize={26} fontFamily={'Merriweather'} fontWeight={600} fontStyle={'italic'} lineHeight={1}>
             {bizName}
            </Typography>
        </Box>
        
        

        </div>
        </div>
       
       
      
    )
}

export default TableQRComponent