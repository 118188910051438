import React from "react"
import Notiflix from "notiflix";
import {Modal,ScrollView,Text,Button,Center,VStack, HStack,Box,Checkbox, Input, IconButton,CheckIcon,DeleteIcon,} from 'native-base'
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { useEffect } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { MdModeEditOutline } from "react-icons/md";
import {
  getFirestore,
} from "redux-firestore";
// export interface DishOption {
//     title: string;
//     required: boolean;
//     toggle: boolean;
//     optionItems: DishOptionItem[];
//   }
  
//   export interface DishOptionItem {
//     name: string;
//     price: number; // in cents
//     count: number;
//     enabled: boolean;
//   }


// TITLE
const DishOptionTitle=({edit,option,title_callback,required_callback})=>{

  const [edited, setEdited ]= useState('')

  const handleRequiredCallback=(value)=>{
    required_callback(value)
  }

  return(
    <HStack justifyContent={'space-between'}>
      {
        edit?
      
          <Input placeholder="Option Title" 
          value={option['title']}
          onChangeText={(text)=>{
            title_callback(text)
          }}/>
   
        :
        <>
       

          <Text fontSize="md" bold>
            {option['title']}
          </Text>

       
  
        </>
        
      }
      <HStack alignItems={'center'}>
          
          <Checkbox onChange={ edit?handleRequiredCallback:undefined} isChecked={option['required']} />
          
          <Text marginLeft={1}>
            Required
          </Text>
      </HStack>


        

    </HStack>
  )
}

// Item
const DishOptionItem =({item,handleOptionChanged,edit,handleDeleteItem})=>{




  return(
    <HStack justifyContent={'space-around'} marginY={1} marginX={2} backgroundColor={!edit?'lightgrey':undefined}>
      {
        edit?
        <>
        <Input
         placeholder="Itemname" 
         value={item['name']}
         onChangeText={(txt)=>{
          handleOptionChanged(txt,undefined,item)
        }} />
        <Input 
        placeholder="item price"

        type=""
        value={item['price']}
        onChangeText={(text)=>{
          handleOptionChanged(undefined,text,item)
        }}/>
        <IconButton onPress={()=>{
                              handleDeleteItem(item)
                            }}>
          <DeleteIcon/>
        </IconButton>
        </>:
        <>
        <Text>
          {item['name']}
        </Text>
        <Text>
          {item['price']}
        </Text>
        </>
        

      }
    </HStack>
  )
}


const DishOptions=({dish,closeHandler,onDishOptionEdited,onDishOptionDeleted})=>{
    // modal that shows/ edits the dish options for the dish
    
    const [edit_dishOptions,setEdit_dishOptions] = React.useState((dish&&dish['dishOptions'])?[...dish['dishOptions']]:[])

    const [currentOption,setCurrentOption]  = React.useState()

    const[addedId,setAddedId] = React.useState(); // to remener what was just added

    const firestore = getFirestore()
   
      // useFirestoreConnect([
      //   {
      //     collection: "DishDetails",
      //     doc:dish?dish['id']:'undefined',
      //     storeAs: 'dishOptions',
      //     subcollections:[{collection:'dishOptions'}]
      //   },
      // ]);

      useEffect(()=>{

        if(dish){
          console.log(dish, firestore)
          const unsubscribe = firestore.collection('DishDetails')
          .doc(dish['id'])
          .collection('dishOptions')
          .onSnapshot((snapshot) => {
            // console.log(snapshot.data())
            const dishOptions=[]
            snapshot.docs.forEach((doc) => {
                console.log('doc',doc.data())
                dishOptions.push(doc.data())
            });
            setEdit_dishOptions(dishOptions)
            setCurrentOption(null)
            setAddedId(null)


            console.log(snapshot.docs.data())
          });
          return () => {
            unsubscribe();
          };
        }
      
      },[dish])
    
   



    
    useEffect(()=>{
        setEdit_dishOptions((dish&&dish['dishOptions'])?[...dish['dishOptions']]:[])
    },[dish])

    console.log(edit_dishOptions, '-------')
  
    // const handleSizeClick = newSize => {

    //   setDishOptions()
    // };

    const addDishOptionBlank=()=>{
      let newDishOption = 
      {
        title: '',
        required: false,
        id:uuidv4(),
        optionItems:[]
      }
      
      setEdit_dishOptions(options=>{
        return(
          [
            ...options,
            newDishOption

          ]
        )
      })
      

      setCurrentOption(newDishOption['id'])
      setAddedId(newDishOption['id'])
    }

    const saveEdited = ()=>{
      const option = edit_dishOptions.find(o=>o['id']===currentOption)
      if(option){
        
        if(option['title'].length >0){
          let hasError = false
          for(let i of option['optionItems']){
            console.log('item',i)
            if (i['name'].length <= 0|| isNaN(i['price'])){
              hasError = true
            }
          }
          if(!hasError){
            onDishOptionEdited(dish,option)
            setCurrentOption(null)
          }else{

            Notiflix.Report.Warning(
              "Warning",
              `please fill all Dish Option information correctly`,
              "Close"
            );
          }
          
        }else{
          Notiflix.Report.Warning(
            "Warning",
            `please fill all Dish Option information`,
            "Close"
          );
        }
        
      }

      
      
    }

    const delete_option = (id)=>{
      const option = edit_dishOptions.find(o=>o['id']===id)
      if(option){
          if(option['id'] === addedId){
            // simply remove
             setEdit_dishOptions((options)=>{
              return [...options.filter(o=>o['id'] !== addedId)]
             })
             console.log('just added')
          }else{
            onDishOptionDeleted(dish,option)
            console.log('delete from fb')
          }
        
             
      }else{
        Notiflix.Report.Warning(
          "Warning",
          `please fill all Dish Option information correctly`,
          "Close"
        ); 
      }
    }

    


    const edit_option = (title,requried,items)=>{
      // adds options depending upon item and for each pram if undefined doenst change value of current edit option
      setEdit_dishOptions(
        options=>{
          let opts = [...options]
          let  option = opts.find(o=>o['id']===currentOption)
          if(option){
            // there is the option required
            if(title !== undefined){
              option['title'] = title
            }
            if(requried!== undefined){
              option['required'] = requried
            }
            if(items !== undefined){
              options['optionItems'] =items
            }
          }

          return opts
        }
      )
    }

    const edit_option_add_item = ()=>{

      setEdit_dishOptions(
        options=>{
          let opts = [...options]
          let  option = opts.find(o=>o['id']===currentOption)
          if(option){
              option['optionItems'] = [
                ...option['optionItems'],
                {
                  name: '',
                  price: 0,
                  id:uuidv4()
                }
              ]
          }else{
            return options
          }
          return opts
        }
      )
      
    }

    const edit_option_item =(name,price,item)=>{
      // editing item
      setEdit_dishOptions(
        options=>{
          let opts = [...options]
          let  option = opts.find(o=>o['id']===currentOption)
          if(option){
             
            let oItems = option['optionItems']
            let o_item = oItems.find(i=>i['id']===item['id'])
            if(o_item){
              if(name !== undefined){
                o_item['name'] = name
              }
              if(price !==  undefined){
                o_item['price'] = price
              }
              
            }
          }

          return opts
        }
      )
      
    }

    
    const deltete_option_item =(item)=>{
      // editing item
      setEdit_dishOptions(
        options=>{
          let opts = [...options]
          let  option = opts.find(o=>o['id']===currentOption)
          if(option){
            let oItems = option['optionItems']
            option['optionItems'] = oItems.filter(i=>i['id']!==item['id'])
          }

          return opts
        }
      )
      
    }

 

   

    
  
    return <>
        <Modal isOpen={!!dish} onClose={closeHandler} size={'xl'}>
          <Modal.Content >
            <Modal.CloseButton />
            <Modal.Header>
              <HStack alignItems={'center'}>
                <Text fontSize={'lg'}>
                 Dish Options
              </Text>
              <Button size={'xs'} marginLeft={2} onPress={addDishOptionBlank} disabled={!!addedId}>
                  Add +
                </Button>
              </HStack>
             
           
            </Modal.Header>
            <Modal.Body>
              <ScrollView>
                <Text fontSize={'md'} colorScheme={'warning'}>
                  Prices in cents **
                </Text>
               {
                  edit_dishOptions.map(
                    item=>{
                      
                      return(
                        <Box borderWidth={2} key={item['id']} borderColor={'grey'} borderRadius={3} padding={1} margin={1}>
                           <HStack justifyContent={'end'}>
                            {/* Controls */}

                            {
                              item.id===currentOption?
                              <IconButton onPress={()=>{
                                console.log('pressed',currentOption)
                                if(currentOption){
                                   saveEdited()
                                }
                              }}>
  
                                <CheckIcon/>
                              </IconButton>:
                              <IconButton onPress={()=>{
                                setCurrentOption(item['id'])
                              }}>
                                <MdModeEditOutline/>
                              </IconButton>

                            }
                           
                            <IconButton onPress={()=>{
                              delete_option(item['id'])
                            }}>
                              <DeleteIcon/>
                            </IconButton>
                           </HStack>
                           {/* Title */}
                           <DishOptionTitle 
                           edit={item.id===currentOption} // edit mode
                           option={item}
                            required_callback={(value)=>{
                            edit_option(undefined,value,undefined)
                           }}
                           title_callback={(title)=>{
                            edit_option(title,undefined,undefined)
                           }}
                           
                           />
                           <Text margin={1} fontSize={'sm'} color={'grey'}>
                            Dish Items
                           </Text>
                            {
                              item['optionItems'].map(o_item=>{
                                return(
                                    <DishOptionItem
                                     edit={item.id===currentOption}
                                      item={o_item} 
                                      handleOptionChanged={edit_option_item}
                                      handleDeleteItem={deltete_option_item}
                                      />
                                )
                              })
                            }

                          {/* Edit add optin  */}
                           {
                            item.id===currentOption?
                            <Button size={'xs'} marginTop={2} onPress={edit_option_add_item}>
                            Add Option Item
                            </Button>:null
                           }
                           {/*  */}

                           


                           
                          

                        

                        </Box>
                      )
                    }
                  )
               }
              </ScrollView>
            </Modal.Body>
            
          </Modal.Content>
        </Modal>
        
      </>
}

export default DishOptions;