import { Heading, HStack, Image, Text, VStack } from "native-base";
import React from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

const _getHasEnabledOptions =(DishOptions)=>{
  
  for (let option of DishOptions){
   
    for (let optionItem of option.optionItems){
      if(optionItem.enabled){
        
         return true
      }
   }
  }

  return false
  
}

const GetDishWithID = ({ item_id ,item,orderTableNo}) => {

  useFirestoreConnect([
    {
      collection: "DishDetails",
      doc: item_id,
    },
  ]);
  let SingleItem = useSelector(
    ({ firestore: { data } }) => data.DishDetails && data.DishDetails[item_id]
  );
  console.log(SingleItem,'ittttt');
  if (item){
    SingleItem = item
  }

  const _getDishOptions =(option)=>{
    console.log(option)

    const getEnabled = ()=>{
      let enabledItems = []
      for (let optionItem of option.optionItems){
         if(optionItem.enabled){
          enabledItems.push(optionItem)
         }
      }
      console.log('enabled',enabledItems)
      return enabledItems
    }

    if(getEnabled().length===0){
      return null
    }
  
    return (
      <HStack>
        <Text>
          {option.title}:
        </Text>
        {getEnabled().map(
          e=>{
            return(
              <Text>
                {e.name},
              </Text>
            )
          }
        )}
      </HStack>
    )
  }



 
  return (
    <>
      {SingleItem && (
        <HStack
          alignItems={"center"}
          justifyContent={"start"}
          space={1}
          p={1}
        >
          <Image
            w={"50"}
            h={"50"}
            borderRadius="25"
            source={{
              uri: SingleItem.DishImage,
            }}
            alt="image"
          />
          <VStack marginLeft={3}>
          <Text textTransform={"capitalize"} ml="-1" fontSize={'lg'}>
            {SingleItem.dishName}
          </Text>
          <Text fontWeight="400">{SingleItem.dishDesc}</Text>
          <Text>
            Count :{SingleItem.dishCount}
          </Text>
          {
            orderTableNo?
            <Text>
              Table No:{orderTableNo}
            </Text>:null
          }
          {
            _getHasEnabledOptions(SingleItem.dishOptions??[])?
            <>
            <Text fontSize={'md'}>
            Options 
          </Text>
          
            {(SingleItem.dishOptions??[]).map(d=>{
              return _getDishOptions(d)
            })}
            </>:null

          }
          
          
          </VStack>
          
        </HStack>
      )}
    </>
  );
};

export default compose(connect())(GetDishWithID);
