import styled from "styled-components";
import FlexBox from "./FlexBox";

const ImageInput = styled(FlexBox)`
  flex-direction: column;
  justify-content: center;
  margin-right: 1rem;
  max-width: 9rem;
  position: relative;
  width: auto;
`;

export default ImageInput;
