import {
  Button,
  Center,
  Divider,
  HStack,
  Image,
  Text,
  VStack,
} from "native-base";
import React from "react";
import logo from "../assets/logo.svg";

const About = () => {
  return (
    <Center>
      <Button
        onPress={() => window.open("/")}
        m={2}
        p={3}
        size={"lg"}
        colorScheme="rose"
        w={"80%"}
      >
        Join Now
      </Button>

      <VStack p={10} pt={0} w={"100%"}>
        <HStack
          flexWrap={"wrap"}
          w={"100%"}
          justifyContent={{ base: "center", md: "space-between" }}
          alignItems="center"
        >
          <HStack>
            <Text color={"red.800"} fontSize={"5xl"}>
              Australia’s
            </Text>
            <Text fontSize={"5xl"}> own</Text>
          </HStack>
          <HStack space={0}>
            <Image
              source={{ uri: logo }}
              resizeMode="contain"
              size={300}
              h="100px"
              alt="NativeBase logo"
            />
            <Text
              position={"absolute"}
              bottom={-5}
              mr={"70px"}
              right={0}
              fontSize={"2xl"}
            >
              .au
            </Text>
          </HStack>
        </HStack>

        <Divider bg={"black"} thickness={2} mt={2} />
        <Center paddingX={"10%"}>
          <HStack
            flexWrap={"wrap"}
            w={"100%"}
            justifyContent="space-around"
            alignItems="center"
          >
            <Image
              source={require("../assets/1.png")}
              resizeMode="contain"
              size={300}
              h="200px"
              alt="1"
            />
            <VStack>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight={"bold"}
                maxW="450"
              >
                In 2022, foot traffic is not enough! Increase your reach,
              </Text>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight={"bold"}
                color="red.800"
              >
                allow your menu to be viewed by people in another suburb!
              </Text>
            </VStack>
          </HStack>
          <Divider thickness={3} />
          <HStack
            flexWrap={"wrap"}
            w={"100%"}
            justifyContent="space-around"
            alignItems="center"
          >
            <VStack>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight={"bold"}
                maxW="450"
              >
                The easiest, quickest, cheapest way for a café/restaurant/bar to
                join the 21st century!
              </Text>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight={"bold"}
                color="red.800"
                maxW="450"
              >
                Minimise over-heads, allow wait staff more time to engage with
                customers, not run around taking orders! No implementation Cost!
              </Text>
            </VStack>
            <Image
              source={require("../assets/2.png")}
              resizeMode="contain"
              size={300}
              h="200px"
              alt="2"
            />
            <Divider thickness={3} />

            <HStack
              flexWrap={"wrap"}
              w="100%"
              justifyContent="space-around"
              alignItems="center"
            >
              <Image
                resizeMode="contain"
                size={300}
                h="200px"
                alt="3"
                source={require("../assets/3.png")}
              />
              <VStack>
                <Text
                  textAlign={"center"}
                  fontSize={{ base: "md", md: "xl" }}
                  fontWeight="bold"
                  maxW={"400"}
                >
                  Don’t be restricted by the number of people that fit through
                  your door,
                </Text>
                <Text
                  textAlign={"center"}
                  fontSize={{ base: "md", md: "xl" }}
                  fontWeight="bold"
                  maxW={"400"}
                  color="red.800"
                >
                  can or cannot catch a view of your menu then are willing to
                  line up to place an order!
                </Text>
              </VStack>
            </HStack>
          </HStack>
          <Divider thickness={3} />
          <HStack
            flexWrap={"wrap"}
            w="100%"
            justifyContent={"space-around"}
            alignItems="center"
          >
            <VStack>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"400"}
              >
                Don’t waste time taking orders at the cash register, asking
                their name, shouting out that name when their order is ready
                then looking around for the customer because they went walk
                about!
              </Text>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"400"}
                color="red.800"
              >
                Only some people are willing to line up!
              </Text>
            </VStack>
            <Image
              resizeMode="contain"
              size={300}
              h="200px"
              alt="3"
              source={require("../assets/4.png")}
            />
          </HStack>
          <Divider thickness={3} />
          <HStack
            flexWrap={"wrap"}
            w="100%"
            justifyContent="space-around"
            alignItems="center"
          >
            <Image
              resizeMode="contain"
              size={300}
              h="200px"
              alt="5"
              source={require("../assets/5.png")}
            />
            <VStack>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"400"}
              >
                The days of crowding around a menu trying to read it or find a
                stack of laminated menus to pick up and possibly spread covid,
              </Text>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"400"}
                color="red.800"
              >
                ARE OVER! Thanks to Piatto.
              </Text>
            </VStack>
          </HStack>
          <Divider thickness={3} />
          <HStack
            flexWrap={"wrap"}
            w="100%"
            justifyContent={"space-around"}
            alignItems="center"
          >
            <VStack>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"400"}
              >
                Make your establishment more accessible, make it easier for the
                disabled, the elderly, the mothers with prams, people with their
                dogs and every other human
              </Text>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"400"}
                color="red.800"
              >
                TO CONSULT your menu and place an order with Piatto!
              </Text>
            </VStack>
            <Image
              resizeMode="contain"
              size={300}
              h="200px"
              alt="6"
              source={require("../assets/6.png")}
            />
          </HStack>
          <Divider thickness={3} />

          <HStack
            flexWrap={"wrap"}
            w="100%"
            justifyContent="space-around"
            alignItems="center"
          >
            <Image
              resizeMode="contain"
              size={300}
              h="200px"
              alt="7"
              source={require("../assets/7.png")}
            />
            <VStack>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"450"}
              >
                Boost engagement, allow your customers to
              </Text>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"400"}
                color="red.800"
              >
                post to your socials directly from within the Piatto app!
              </Text>
            </VStack>
          </HStack>
          <Divider thickness={3} />
          <HStack
            flexWrap={"wrap"}
            w="100%"
            justifyContent={"space-around"}
            alignItems="center"
          >
            <VStack>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"400"}
              >
                Fondle less with Eftpos machines, worry less about getting a
                customer’s credit card, swiping it and it possibly not working!
              </Text>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"400"}
                color="red.800"
              >
                Forget credit card surcharge!
              </Text>
            </VStack>
            <Image
              resizeMode="contain"
              size={300}
              h="200px"
              alt="8"
              source={require("../assets/8.png")}
            />
          </HStack>

          <Divider thickness={3} />
          <HStack
            flexWrap={"wrap"}
            w="100%"
            justifyContent="space-around"
            alignItems="center"
          >
            <Image
              resizeMode="contain"
              size={300}
              h="200px"
              alt="7"
              source={require("../assets/9.png")}
            />
            <VStack>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"450"}
              >
                Do you have regular customers that often have the same item?
                Make it easier for them to place their order, pay for their
                order,
              </Text>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"450"}
                color="red.800"
              >
                so they can spend more time enjoying their order and still enjoy
                the customer-barista/waiter/bar-tender engagement!
              </Text>
            </VStack>
          </HStack>
          <Divider thickness={3} />
          <HStack
            flexWrap={"wrap"}
            w="100%"
            justifyContent={"space-around"}
            alignItems="center"
          >
            <VStack>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"450"}
              >
                Boost engagement, encourage return customers!
              </Text>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"450"}
                color="red.800"
              >
                You won’t loose touch with customers just because they left your
                shop!
              </Text>
            </VStack>
            <Image
              resizeMode="contain"
              size={300}
              h="200px"
              alt="8"
              source={require("../assets/10.png")}
            />
          </HStack>
          <Divider thickness={3} />
          <HStack
            flexWrap={"wrap"}
            w="100%"
            justifyContent="space-around"
            alignItems="center"
          >
            <Image
              resizeMode="contain"
              size={300}
              h="200px"
              alt="7"
              source={require("../assets/11.png")}
            />
            <VStack>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"450"}
              >
                Boost engagement,
              </Text>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"450"}
                color="red.800"
              >
                know your customer’s name, receive their order, have it paid
                for, from their table or even before they arrive!
              </Text>
            </VStack>
          </HStack>
          <Divider thickness={3} />
          <HStack
            flexWrap={"wrap"}
            w="100%"
            justifyContent={"space-around"}
            alignItems="center"
          >
            <VStack>
              <Text
                textAlign={"center"}
                fontSize={{ base: "xl", md: "3xl" }}
                fontWeight="bold"
                maxW={"450"}
              >
                It’s inevitable, the future of ‘browsing’, is Piatto!
              </Text>
              <Text
                textAlign={"center"}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight="bold"
                maxW={"450"}
                color="red.800"
              >
                Piatto is here to help Australia’s hospitality venues, American
                conglomerates no longer write the rules!
              </Text>
              <Text borderWidth={2} p={2} borderColor="red.800">
                Daniel Henshall | 0410 526 465 | daniel@piatto.com.au |
                piatto.com.au
              </Text>
            </VStack>
            <Image
              resizeMode="contain"
              size={400}
              h="300px"
              alt="8"
              source={require("../assets/12.png")}
            />
          </HStack>
          <Divider thickness={3} />
        </Center>
        <Button
          alignSelf={"center"}
          onPress={() => window.open("/")}
          m={2}
          p={3}
          size={"lg"}
          colorScheme="rose"
          w={"80%"}
        >
          Join Now
        </Button>
      </VStack>
    </Center>
  );
};

export default About;
