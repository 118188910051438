import React, { useRef, useEffect } from "react";
import { IconButton, HamburgerIcon, Box } from "native-base";
import { useHistory } from "react-router-dom";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";

import logo from "../assets/logo.svg";
import yt from "../assets/yt.png";
import fb from "../assets/fb.png";
import twitter from "../assets/twitter.png";
import insta from "../assets/instagram.png";
import substack from "../assets/substack.png";
import linkedin from "../assets/linkedin.png";
import VenueCTA from "../assets/VenueCTA.png";
import discord from "../assets/discord_small.png";

const Header2 = () => {
  const history = useHistory();
  const scrollToContact = () => {
    document
      .querySelector(".div-contact")
      .scrollIntoView({ behavior: "smooth" });
  };
  const scrollToMainAbout = () => {
    var position = document.querySelector(".div-about").scrollTop;

    window.scrollTo({ top: position, behavior: "smooth" });
  };

  useEffect(() => {
    const button = document.getElementById("my-button");
    const hideMenu = document.querySelector(".div-inter-font");

    button.addEventListener("click", () => {
      toggleNavAndHamBtn();
    });

    function toggleNavAndHamBtn() {
      hideMenu.classList.toggle("show");
      hideMenu.classList.remove("hide");
      button.classList.toggle("open");
    }
    const handleScroll = () => {
      const scrollTo = document.querySelector("#div-vendor-about");
      const stickySpan = document.getElementById("sticky-span");
      const scrollToRect = scrollTo.getBoundingClientRect();

      if (scrollToRect.top + 50 < window.innerHeight) {
        stickySpan.classList.add("stick");
        hideMenu.classList.remove("show");
        hideMenu.classList.add("hide");
        button.classList.remove("open");
      } else {
        stickySpan.classList.remove("stick");
        hideMenu.classList.remove("hide");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav className="div-header">
        <img
          onClick={() => window.location.reload()}
          loading="lazy"
          srcSet={logo}
          className="img-header pointer"
          alt="NativeBase logo"
        />
        <span id="sticky-span" className="" >
          <img
            id="venue-sign-up-button-sticky"
            onClick={() => history.push("/SignUp")}
            loading="lazy"
            src={VenueCTA}
            className="pointer"
          />
        </span>


        <button class="my-button" id="my-button">
          <Box bg="#121212" p={2}>
            <IconButton
              aria-label="Scroll to Top"
              icon={<HamburgerIcon size="xl" color="white" />}
              variant="unstyled"
            />
          </Box>
        </button>
        <ul id="mynav" className="div-inter-font">
          <li>
            <div className="pointer" onClick={() => history.push("/SignUp")}>
              Venue Sign-Up
            </div>
          </li>
          <li>
            <div
              className="pointer"
              onClick={() => {
                history.push("/SignIn");
              }}
            >
              Venue Log-In
            </div>
          </li>
          <li>
            <div onClick={scrollToContact} className="pointer">
              Contact
            </div>
          </li>


        </ul>

        <div className="div-social-media-block"> </div>

      </nav>
      <style jsx>{`
        * {
          margin: 0;
          padding: 0;
        }

        .my-button {
          background-color: transparent;
          border: 10px;
          color: white;
          cursor: pointer;
          font-size: 30px;
          float: left;
          line-height: 70px;
          display: none;
        }

        .my-button:focus {
          outline: none;
        }
        .my-button.open {
          transform: rotate(90deg); /* Rotate the button when open */
        }
        nav {
          background-color: grey;
          flex-wrap: wrap;
        }

        nav ul {
          display: flex;
          justify-content: center;
          list-style: none;
          left-margin: -50px;
        }

        nav ul li {
          padding: 20px 20px;
        }

        nav ul li a {
          color: white;
          text-decoration: none;
        }

        @media (max-width: 800px) {
          #sticky-span.stick {
            width: 40% !important;
          }
          nav ul {
            display: none;
            flex-direction: column;
            width: 100%;
          }

          nav ul.show {
            display: flex;
            transition: opacity 0.3s ease-in-out;
          }

          .my-button {
            display: block;
          }
        }

        @media (max-width: 991px) {
          #sticky-span {
            margin-right:50px;
          }
          nav ul {
            display: none;
            flex-direction: column;
            width: 100%;
          }

          nav ul.show {
            display: flex;
            transition: opacity 0.3s ease-in-out;
          }

          .my-button {
            display: block;
          }
          nav {
            background-color: rgb(62, 109, 149);
          }
        }

        .div-header {
          position: fixed; /* Fix the position to the top of the screen */
          top: 0; /* Align to the top */
          background-color: #121212; /* Black background */
          width: 100%; /* Full width */
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0px 20px; /* Add padding for spacing */
          z-index: 1000; /* Ensure it's above other content */
        }

        #div-venu-sign-up {
          display: none;
        }

        #venue-sign-up-button-sticky {
          width: 200px;
          margin-right: 100px;
        }
        @media (max-width: 991px) {
          #venue-sign-up-button-sticky {
            width: 240px;
            margin-right: 0px;
          }
          .div-header {
            flex-wrap: wrap;
          }
          #div-venu-sign-up {
            display: flex;
          }
          .img-header {
            width: 22% !important;
          }
          // .div-social-media {
          //   display: none !important;
          // }
          .div-social-media-block {
            display: none !important;
          }
        }
        .div-social-media-block {
          width: 180px;
        }
        .img-header {
          aspect-ratio: 1.82;
          object-fit: auto;
          object-position: center;
          width: 10%;
          align-self: stretch;
          max-width: 100%;
        }
        .div-inter-font {
          align-self: stretch;
          justify-content: space-between;
          gap: 20px;
          font-size: 14px;
          color: #fff;
          font-weight: 400;
          margin: auto 0;
          margin-left: -20px;
          // margin-right: 50px;
          font-family: Inter, Inclusive Sans, sans-serif;
        }
        .div-inter-font.hide {
          display: none;
        }

        .img-small {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 20px;
          height: 15px;
        }
        .pointer {
          cursor: pointer;
        }

        #sticky-span {
          display: none;
        }
        #sticky-span.stick {
          display: flex;
          width: 8%;
        }
       
      `}</style>
    </>
  );
};
export default Header2;
