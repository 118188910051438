import * as React from "react";

function DeleteIcon(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      width="401pt"
      height="401pt"
      style={{ marginLeft: "0.5rem", height: "1rem" }}
      {...props}
    >
      <path
        fill="#E04F5F"
        d="M504.1 256C504.1 119 393 7.9 256 7.9S7.9 119 7.9 256 119 504.1 256 504.1 504.1 393 504.1 256z"
      />
      <path
        fill="#FFF"
        d="M285 256l72.5-84.2c7.9-9.2 6.9-23-2.3-31-9.2-7.9-23-6.9-30.9 2.3L256 222.4l-68.2-79.2c-7.9-9.2-21.8-10.2-31-2.3-9.2 7.9-10.2 21.8-2.3 31L227 256l-72.5 84.2c-7.9 9.2-6.9 23 2.3 31 4.1 3.6 9.2 5.3 14.3 5.3 6.2 0 12.3-2.6 16.6-7.6l68.2-79.2 68.2 79.2c4.3 5 10.5 7.6 16.6 7.6 5.1 0 10.2-1.7 14.3-5.3 9.2-7.9 10.2-21.8 2.3-31L285 256z"
      />
    </svg>
  );
}

export default DeleteIcon;
