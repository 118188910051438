

import React, { useEffect } from "react"
import {Modal,ScrollView,Select,Text,Button,Center,VStack,FormControl, HStack,Box,Checkbox, Input, IconButton,CheckIcon,DeleteIcon,} from 'native-base'
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Notiflix from "notiflix";
import TableQRComponent from "./assetComponent/tableQr";
const filter = createFilterOptions();
const TableDetailsModal =({bizId,bizName,table,closeHandler,categories,onSave,downloadQR,onDelete})=>{

    const [category,setCategory] = React.useState(table?{
        title:table.category
    }:null)

    useEffect(()=>{
         if(table){
            setCategory({
                title:table.category
            })
         }
    },[table])

    console.log(categories)
    if(!table){
        return null
    }
   
    return(
        <React.Fragment>
            <Modal isOpen={!!table} onClose={()=>{
              
                setCategory(null)
                closeHandler()
            }} size={'xl'}>
          <Modal.Content >
            <Modal.CloseButton />
            <Modal.Header>
              <HStack alignItems={'center'}>
                <Text fontSize={'lg'}>
                Table Details
              </Text>
              <IconButton onPress={()=>{
                              onDelete(table)
                            }}>
                <DeleteIcon/>
                </IconButton>
              
              </HStack>
            
            </Modal.Header>
            <Modal.Body>
            <HStack alignItems={'center'}>
            
              <Text fontSize={'md'}>
                 Table Location :
              </Text>
              <Autocomplete
                size={'small'}
                value={category}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setCategory({
                        title: newValue,
                    });
                    } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setCategory({
                        title: newValue.inputValue,
                    });
                    } else {
                        setCategory(newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                    });
                    }

                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={categories.map(c=>{
                    return{
                        title:c
                    }
                })}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                    return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                    return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                }}
                renderOption={(props, option) => <li {...props}>{option.title}</li>}
                sx={{ width: 300,margin:2 }}
                freeSolo

                renderInput={(params) => (
                    <TextField {...params} label="Enter Location or category" />
                )}

    />

              </HStack>
              <HStack alignItems={'center'}>
                <Text fontSize={'md'}>
                Table Number :
                </Text>
                <Text fontSize={'md'}>
                {table['tableNo']}
                </Text>
                
              </HStack>

              <Button marginTop={3} onPress={()=>{
              if(category && (category.title??[]).length>1){
                onSave({
                    ...table,
                    category:category.title
                  })
              }     
            
              }}>
                Save
              </Button>
              <Button marginTop={3} onPress={()=>{
                 console.log('press',table)
                 downloadQR(table)
              }}>
                Download Qr
              </Button>
              
              
            
            </Modal.Body>
            </Modal.Content>
            </Modal>
            <TableQRComponent bizName={bizName} bizId={bizId} tableId={table['tableNo']}/>
        </React.Fragment>
    )
}

export default TableDetailsModal